<template lang="pug">
.income-list-page.flex.column.align-center
  .content.flex.column.align-stretch.pt-40.gap-20
    .title.font-size-20.bold 薪資歷史紀錄
    .years.flex.column
      .year.flex.column.gap-8(v-for="l in list", :key="l.year")
        .year-title.flex.row.align-center.pl-12.pr-12.gap-8.clickable(
          @click="toggleShow(l)")
          i.el-icon-date
          span {{ l.year }}
        template(v-if="l.show")
          .month.flex.row.align-center.pl-12.pr-12.clickable(
            @click="goPayslipMonth(d)"
            v-for="d in l.data")
            .content.bold {{ d.year }}年 {{ d.month }}月
</template>

<script>
import {
  GetConfirmedPayments,
} from '@/site/professional/api/accounting';

export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    goPayslipMonth(d) {
      this.$router.push(`/payment/${d.year}/${d.month}`);
    },
    toggleShow(l) {
      // eslint-disable-next-line no-param-reassign
      l.show = !l.show;
      this.$forceUpdate();
    },
    loadData() {
      this.$execWithLoading(async () => {
        const rsp = await GetConfirmedPayments();
        const yearMap = {};
        const list = [];
        rsp.forEach((payslip) => {
          if (!yearMap[payslip.year]) {
            const l = {
              year: payslip.year,
              data: [],
              show: true,
            };
            list.push(l);
            yearMap[payslip.year] = l;
          }
          yearMap[payslip.year].data.push(payslip);
        });
        this.list = list;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.income-list-page {
  .content {
    width: calc(100vw - 40px);
    max-width: 600px;
    .years {
      .year-title {
        flex: 0 0 40px;
        background: white;
        border-radius: 4px;
      }
      .month {
        flex: 0 0 40px;
        background: white;
        border-radius: 4px;
      }
    }
  }
}
</style>
