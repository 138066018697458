<template lang="pug">
.record-editor.flex.column.align-center.overflow-overlay(:class="{'show': showEditor}")
  .header.flex.align-center
    el-button(round, size="mini", icon="el-icon-arrow-left", @click="close") 返回
  .content.card-style.flex.column.gap-10(v-if="!editing")
    .column.flex.column.gap-4
      .name.text-secondary 個案
      .value.flex.align-center
        span {{ patient?.family_name }}
        span {{ patient?.given_name }}
    .column.flex.column.gap-4
      .name.text-secondary 預約時間與時長
      .value {{ $timestampToDateTimeMinuteWithDay(data.start_at) }} ({{ data.length }} hr)
    .column.flex.column.gap-4
      .name.text-secondary 預約型態
      .value {{ $tryMsg(`appointment.locationType.${data.location_type}`) || '---' }}
    .column.flex.column.gap-4
      .name.text-secondary 本次諮商
      .value {{ $tryMsg(`appointment.type.${data.appointment_type}`) || '---' }}
    .column.flex.column.gap-4
      .name.text-secondary 專案
      .value {{ data.project || '---' }}
    .column.flex.row.align-center.justify-start
      el-button(type="primary", @click="startEditing") 編輯個案記錄
      .empty.flex-1
  .edit-content.flex.column.align-stretch.gap-30(v-else)
    template(v-if="templates.length > 0")
      .rows.flex.column
        .row 專案：{{ data.project }}
        .row (需填寫專案範本, 點選連結下載範本)
        .files.flex.column
          .file.flex.align-center(v-for="file in templates", :key="file.url")
            a.flex.align-center.gap-4(target="_blank", :href="file.url")
              i.el-icon-document
              span {{ file.filename }}
      .splitter
      .upload-area.flex.column.gap-10
        .desc.text-secondary 必須將完成的個案記錄上傳，並且完成下方蛹之生的個案記錄文件
        el-button(type="primary", @click="addUpload") 上傳個案記錄
        input.file-input(
          type="file", ref="upload",
          @input="changeFile")
        .uploaded-files.flex.column.align-start.gap-6
          .file.flex.align-center.gap-6(v-for="(file, idx) in files", :key="idx")
            i.el-icon-document
            .name {{ file?.name }}
            i.el-icon-delete.danger-color(@click="deleteAttach(idx)")
      .splitter
    //- .title 諮詢類別：{{ inputSetting.label }}
    edit-block(
      ref="block"
      v-for="(block, idx) in showBlocks", :key="idx"
      :index="idx + 1",
      :valueKey="block.key",
      :title="block.title",
      :tags="block.tags",
      :minTagNum="block.minTagNum",
      :maxTagNum="block.maxTagNum",
      :require="block.require"
    )
    .buttons.flex.align-center.p-12
      el-button(@click="close") 取消
      el-button(type="primary", @click="save(false)") 儲存草稿
      el-button(type="primary", @click="save(true)") 完成
</template>

<script>
import {
  getAppointment,
  updateAppointmentRecord,
  getRecordTags,
  getAppointmentRecord,
  getAppointmentRecordTemplate,
  addAppointmentRecordFile,
  deleteAppointmentAttachment,
} from '@/api/reserve';
import {
  getLastPatientRecordWithRecords,
} from '@/api/patient';
import { blocks, noProjectTags } from '@/site/professional/data/caseProp';
import EditBlock from '@/site/professional/components/record/EditBlock.vue';

export default {
  name: 'record-editor',
  components: {
    EditBlock,
  },
  watch: {
    $route() {
      this.id = undefined;
    },
  },
  data() {
    return {
      id: undefined,
      lastID: undefined,
      data: {},
      editing: false,
      projectType: 'mental',
      blocks,
      hasOld: false,
      templates: [],
      files: [],
      patient: {},
    };
  },
  computed: {
    hasProject() {
      return this.data?.project !== '';
    },
    showEditor() {
      return this.id !== undefined;
    },
    showBlocks() {
      return this.blocks.filter(
        (b) => !b.main_requirement_type
          || !this.data.main_requirement_type
          || b.main_requirement_type === this.data.main_requirement_type,
      );
    },
    // inputSetting() {
    //   return this.setting[this.projectType];
    // },
  },
  methods: {
    deleteAttach(idx) {
      const removeFile = this.files[idx];
      this.$execWithLoading(async () => {
        await deleteAppointmentAttachment(this.id, removeFile.id);
        this.files.splice(idx, 1);
        this.$forceUpdate();
      }, (e) => {
        this.$showAxiosException('移除附件失敗', e);
      });
    },
    addUpload() {
      this.$refs.upload.click();
    },
    async changeFile() {
      if (this.$refs.upload.files.length < 1) {
        return;
      }
      await this.$execWithLoadingMessage('檔案上傳中', async () => {
        const file = this.$refs.upload.files[0];

        const rsp = await addAppointmentRecordFile(this.id, file);

        this.files.push({
          name: file.name,
          id: rsp.id,
          file,
        });
      });
      this.$refs.upload.value = '';
    },
    async startEditing() {
      this.editing = true;
      this.$nextTick(async () => {
        await this.$execWithLoading(async () => {
          const hasData = await this.loadCurrentRecord();
          if (!hasData && this.lastID) {
            const lastData = await getAppointmentRecord(this.lastID);
            console.log(lastData);
            this.$refs.block.forEach((b) => {
              const key = b.getKey();
              const tagKey = `${key}_tags`;
              if (key === 'medical_history') {
                b.setValue(lastData[key], lastData[tagKey] || []);
              }
            });
          }
        }, (e) => {
          this.$showAxiosException('載入上次紀錄失敗', e);
        });
      });
    },
    async loadCurrentRecord() {
      try {
        const rsp = await getAppointmentRecord(this.id);
        this.$refs.block.forEach((b) => {
          const key = b.getKey();
          const tagKey = `${key}_tags`;
          b.setValue(rsp[key], rsp[tagKey] || []);
        });
        this.files = (rsp.attachments || []).map((a) => ({
          name: a.filename,
          id: a.id,
        }));
        return true;
      } catch (e) {
        return false;
      }
    },
    reset() {
      this.id = undefined;
      this.data = {};
      this.editing = false;
      this.projectType = 'mental';
    },
    save(publish) {
      let canSave = true;
      const query = {};
      this.$refs.block.forEach((b) => {
        if (publish) {
          // only check when publish
          canSave = canSave && b.validate();
        }
        const value = b.getValue();
        query[value.key] = value.data;
        query[`${value.key}_tags`] = value.tags;
      });
      if (!canSave) {
        return;
      }
      query.publish = publish;
      query.attachments = this.files.map((f) => f.id);
      console.log(query);

      this.$execWithLoading(async () => {
        await updateAppointmentRecord(this.id, query);
        if (publish) {
          this.$showSuccess('已送出個案紀錄');
          this.$emit('finish');
          this.close();
        } else {
          this.$showSuccess('已儲存個案紀錄');
        }
      }, (e) => {
        console.log(e);
        this.$showAxiosException('儲存失敗', e);
      });
    },
    close() {
      this.id = undefined;
      this.data = {};
      this.editing = false;
      this.projectType = 'mental';
      this.hasOld = false;
      this.templates = [];
      this.files = [];
    },
    loadData() {
      this.$execWithLoading(async () => {
        await Promise.all([
          this.loadRecordData(),
          this.loadLastRecord(),
        ]);
      }, (e) => {
        this.$showAxiosException(e);
        this.close();
      });
    },
    async loadLastRecord() {
      const lastRecord = await getLastPatientRecordWithRecords(this.patient?.id);
      console.log(lastRecord);
      this.lastID = lastRecord.id;
    },
    async loadRecordData() {
      const data = await getAppointment(this.id);
      this.data = {
        ...data,
        length: ((data.end_at - data.start_at) / 3600).toFixed(1),
      };
      this.projectType = data.appointment_type;

      let tags;
      if ((data?.project || '') === '') {
        tags = noProjectTags;
      } else {
        tags = await getRecordTags(this.id);
      }
      blocks.forEach((block) => {
        const tagKey = `${block.key}_tags`;
        const values = tags[tagKey] || [];

        console.log({ tagKey, values });
        values.forEach((value) => {
          if (value.text === value.tag) {
            // eslint-disable-next-line no-param-reassign
            value.text = this.$msg(`tag.${block.key}.${value.tag}`);
          }
        });

        // eslint-disable-next-line no-param-reassign
        block.tags = values;
      });

      const rsp = await getAppointmentRecordTemplate(this.id);
      this.templates = rsp.map((t) => ({
        ...t,
        filename: t.filename.replace(/^\('/g, '').replace(/',\)$/g, ''),
      }));
    },
    loadMockData() {
      this.data = {
        id: 91,
        price: 1200.0,
        patient: {
          id: 5,
          family_name: 'A',
          given_name: 'CC',
        },
        start_at: 1681092000.0,
        end_at: 1681095600.0,
        length: ((1681095600.0 - 1681092000.0) / 3600).toFixed(1),
        location_type: 'virtual',
        room: {
          id: 2,
          name: '空間二',
          branch: {
            id: 1,
            name: '總館',
          },
        },
        record_finished: false,
        can_edit_record: false,
        project: '112年組織',
      };
    },
  },
  mounted() {
    this.$on('show', (id) => {
      this.reset();
      this.id = id.id || id;
      this.patient = id.patient || {};
      this.loadData();
    });
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.record-editor {
  height: calc(100vh - #{$header-height});
  height: calc(100dvh - #{$header-height});
  width: 100vw;
  background: #F9F9F9;
  position: absolute;
  opacity: 0.01;
  transition: all .5s ease-in-out;
  pointer-events: none;
  top: $header-height;
  left: 0;
  z-index: 2;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  .header {
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100vw;
    flex: 0 0 46px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #DCDFE6;
    padding: 0 20px;
    z-index: 10;
  }
  .content {
    width: calc(100vw - 40px);
    max-width: 600px;
    padding: 20px;
    margin-top: 30px;
  }
  .edit-content {
    width: 100vw;
    max-width: 600px;
    flex: 1;
    padding-top: 50px;
    padding-bottom: 50px;
    .title {
      margin-bottom: -20px;
    }
    .files {
      background: #ECF5FF;
      padding: 10px;
      .file > a {
        text-decoration: none;
        color: #409EFF;
      }
    }
  }
}
.file-input {
  max-height: 0;
  max-width: 0;
  overflow: hidden;
}
.splitter {
  width: 100%;
  flex: 0 0 1px;
  background:#DCDFE6;
}

@media only screen and (max-width: 644px) {
  .record-editor {
    height: calc(100vh - 40px);
    height: calc(100dvh - 40px);
    top: 40px;
  }
}
</style>
