<template lang="pug">
.calendar-block.flex.column(
  :style="style"
)
  .block.flex-1.flex.column.clickable(:style="blockStyle", @click="showDetail")
    template(v-if="isActivity")
      .header.flex.align-center
        .name.bold.flex-1 {{ data.name }}
        i.el-icon-office-building(v-if="data?.location_type === 'inhouse'")
        i.el-icon-phone-outline(v-if="data?.location_type === 'virtual'")
        i.el-icon-odometer(v-if="data?.location_type === 'otherplace'")
      .value.flex.align-center.gap-4(v-if="data?.location_type === 'inhouse'")
        span {{ data?.room?.branch?.name }}
        span /
        span {{ data?.room?.name }}
      .value.flex.align-center.wrap(v-if="data?.location_type === 'otherplace'")
        span {{ data?.county || '' }}
        span {{ data?.district || '' }}
        span {{ data?.address || '' }}
    template(v-else)
      .header.flex.align-center
        .type.flex-1(v-if="data?.location_type === 'virtual'") 遠端諮商
        .type.flex-1.flex.align-center.gap-4(v-if="data?.location_type === 'inhouse'")
          span {{ data?.room?.branch?.name }}
          span /
          span {{ data?.room?.name }}
        i.el-icon-office-building(v-if="data?.location_type === 'inhouse'")
        i.el-icon-phone-outline(v-if="data?.location_type === 'virtual'")
      .value.flex.align-center.wrap.gap-4
        .name.bold.link-text.clickable(
          @click="showPatient(data.patient.id, $event)"
          ) {{ data?.patient.family_name }}{{ data?.patient.given_name }}
        template(v-if="data?.appointment_type !== 'parent'")
          template(v-for="(p, idx) in data?.partners")
            span(:key="`dot-${idx}`") 、
            .name.bold.link-text.clickable(
              :key="p.id"
              @click="showPatient(p.id, $event)"
              ) {{ p.family_name }}{{ p.given_name }}
        template(v-else)
          template(v-for="(p, idx) in data?.partners")
            .name.clickable.ml-4.flex.align-center.gap-4.link-text.font-size-12(
              :key="p.id"
              @click="showPatient(p.id, $event)"
              )
              span (
              template(v-if="data?.appointment_subtype === 'statutoryagents'")
                span 法代
                span :
              template(v-else-if="data?.appointment_subtype === 'children'")
                span 子女
                span :
              span {{ p.family_name }}{{ p.given_name }}
              span )
</template>

<script>
export default {
  name: 'calendar-block',
  props: {
    data: {
      type: Object,
      required: true,
    },
    isActivity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    day() {
      const shift = (new Date(this.data?.start_at * 1000)).getDay();
      return shift === 0 ? 7 : shift;
    },
    hour() {
      return (new Date(this.data?.start_at * 1000)).getHours();
    },
    half() {
      return (new Date(this.data?.start_at * 1000)).getMinutes() >= 30;
    },
    yPos() {
      return this.hour * 60 * 2 + (this.half ? 60 : 0) + 60;
    },
    mins() {
      return (this.data?.end_at - this.data?.start_at) / 60;
    },
    height() {
      const slots = parseInt(this.mins / 30, 10);
      return slots * 60;
    },
    bgColor() {
      if (this.isActivity) {
        return '#DFF2F1';
      }
      const payment = this.data.payment || this.data.payment_method;
      if (!this.data.project && this.data.location_type === 'virtual'
       && (payment === undefined || payment === 'notpaid')) {
        return '#FEF0F0';
      }
      return '#ECF5FF';
    },
    borderColor() {
      if (this.isActivity) {
        return '#02A1A1';
      }
      const payment = this.data.payment || this.data.payment_method;
      if (!this.data.project && this.data.location_type === 'virtual'
       && (payment === undefined || payment === 'notpaid')) {
        return '#F89898';
      }
      return '#79BBFF';
    },
    style() {
      return {
        left: `${80 + 160 * (this.day - 1)}px`,
        top: `${this.yPos}px`,
        height: `${this.height}px`,
      };
    },
    blockStyle() {
      return {
        backgroundColor: this.bgColor,
        borderColor: this.borderColor,
      };
    },
  },
  methods: {
    showPatient(id, e) {
      this.$emit('showPatient', id);
      e.stopPropagation();
      e.preventDefault();
    },
    showDetail() {
      if (this.isActivity) {
        this.$emit('showActivity', this.data);
      } else {
        this.$emit('showDetail', this.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.calendar-block {
  position: absolute;
  box-sizing: border-box;
  width: 160px;
  padding: 2px;
  .block {
    border-radius: 4px;
    padding: 5px;
    background: #DFF2F1;
    border: 1px solid #02A1A1;
    i {
      color: $primary-color;
    }
  }
}
</style>
