<template lang="pug">
el-drawer(
  title="預約詳細資訊"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody"
  :wrapperClosable="false")
  .drawer.flex.column.align-stretch.gap-16
    .col.flex.column.align-stretch.gap-4(
      v-for="col in columns", :key="col.key"
    )
      .name {{ col.text }}
      .value.flex.align-center
        template(v-if="col.key === 'payment'")
          span.danger-color(v-if="data[col.key] === 'notpaid'") 尚未支付
          span(v-else-if="data[col.key] === 'cash'") 現金支付
          span(v-else-if="data[col.key] === '3rdparty'") 藍新金流
          span(v-else-if="data[col.key] === 'creditcard'") 信用卡支付
          span(v-else-if="data[col.key] === 'transfer'") 轉帳支付
          span(v-else-if="data[col.key] === 'project'") 專案支付
        template(v-else-if="col.key === 'space'")
          .lines.flex.column.align-start
            span {{ data[col.key] }}
            span.clickable.link-text(
              v-if="data.locationType === '遠距諮商'"
              @click="goLink")
              span 遠距諮商
              i.el-icon-link
        template(v-else) {{ data[col.key] }}
</template>

<script>

export default {
  name: 'appointment-detail',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      columns: [
        {
          text: '個案姓名',
          key: 'name',
        },
        {
          text: '預約時間與時長',
          key: 'time',
        },
        {
          text: '專案',
          key: 'project',
        },
        // {
        //   text: '本次諮商為',
        //   key: 'appointmentType',
        // },
        {
          text: '預約型態',
          key: 'locationType',
        },
        {
          text: '預約空間',
          key: 'space',
        },
        {
          text: '預約價位',
          key: 'price',
        },
        {
          text: '支付記錄',
          key: 'payment',
        },
        {
          text: '本次預約備註',
          key: 'note',
        },
      ],
      data: {},
    };
  },
  methods: {
    goLink() {
      if (this.data.locationType === '遠距諮商' && this.data.payment === 'notpaid') {
        this.$alert('此預約尚未完成付款，唔談空間暫不開放', '提醒', {
          confirmButtonText: '知道了',
        });
        return;
      }
      this.$goLink(this.data.meetingLink);
    },
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show(data) {
      this.drawer = true;

      const time = `${
        this.$timestampToDateTimeMinuteWithDay(data.start_at)
      } (${
        ((data.end_at - data.start_at) / 3600).toFixed(1)
      } hr)`;
      let name = `${data.patient.family_name}${data.patient.given_name}`;
      (data?.partners || []).forEach((partner) => {
        name = `${name}、${partner.family_name}${partner.given_name}`;
      });
      const organization = data.project_organization;
      this.data = {
        name,
        time,
        project: data.project ? `${organization} - ${data.project}` : '無',
        appointmentType: '',
        locationType: data.location_type === 'inhouse' ? '實體諮商' : '遠距諮商',
        space: `${data?.room?.branch?.name} ${data?.room?.name}`,
        price: data.price,
        payment: data.payment_method || 'notpaid',
        note: data?.note || '無',
        meetingLink: data.meeting_link,
        origData: data,
      };
    },
    reset() {
      this.drawer = false;
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.show(data);
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
  .col {
    .name {
      color: #909399;
      font-size: 12px;
    }
  }
}
</style>
