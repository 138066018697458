<template lang="pug">
.tag-toggle.clickable(
  :class="{'disable': disable}"
  @click="chooseThis")
  el-tag(:type="computedType", :effect="effect")
    slot
</template>

<script>
export default {
  name: 'TagToggle',
  props: {
    type: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    effect() {
      if (this.disable) {
        return 'dark';
      }
      return this.value === this.label ? 'dark' : 'plain';
    },
    computedType() {
      if (this.disable) {
        return 'info';
      }
      return this.type;
    },
  },
  methods: {
    chooseThis() {
      if (this.disable) {
        return;
      }
      this.$emit('input', this.label);
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable.disable {
  cursor: not-allowed;
}
</style>
