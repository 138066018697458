<template lang="pug">
.edit-block.flex.column.align-stretch.gap-10.p-12
  .title.bold.flex.align-center.gap-6
    .index(v-if="index !== 0") {{ index }}.
    .desc {{ title }}
  el-input(
    type="textarea"
    :placeholder="placeholder"
    v-model="data"
    :rows="6"
  )
  .danger-color(v-if="showInputErr") 此為必填
  template(v-if="maxTagNum > 0")
    .desc.flex.align-center.gap-6.text-secondary
      .desc 請選擇
      .desc(v-if="minTagNum === maxTagNum") {{ minTagNum }}
      .desc(v-else) {{ minTagNum }}~{{ maxTagNum }}
      .desc 個標籤
    .tags.flex.align-center.wrap.gap-10(
      :class="{'not-clickable': selectedNum >= maxTagNum}"
    )
      .tag.clickable(
        :class="{'selected': tag.selected}"
        v-for="tag in localTags", :key="tag.tag"
        @click="toggleTag(tag)"
      ) {{ tag.text }}
  .danger-color(v-if="showTagErr") 選擇標籤為必填
</template>

<script>
export default {
  name: 'edit-block',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    valueKey: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '請輸入內容並選擇標籤',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    tagDirection: {
      type: String,
      default: 'row',
    },
    minTagNum: {
      type: Number,
      default: 1,
    },
    maxTagNum: {
      type: Number,
      default: 3,
    },
    require: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: '',
      localTags: [],
      showInputErr: false,
      showTagErr: false,
    };
  },
  computed: {
    selectedTags() {
      return this.localTags.filter((t) => t.selected) || [];
    },
    selectedNum() {
      return this.selectedTags.length;
    },
  },
  methods: {
    toggleTag(tag) {
      if (!tag.selected && this.selectedNum >= this.maxTagNum) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      tag.selected = !tag.selected;
    },
    validate() {
      let hasErr = false;
      this.showInputErr = false;
      this.showTagErr = false;
      if (this.require && this.data === '') {
        this.showInputErr = true;
        hasErr = true;
      }
      if (this.maxTagNum === 0) {
        return !hasErr;
      }
      if (this.selectedNum < this.minTagNum || this.selectedNum > this.maxTagNum) {
        this.showTagErr = true;
        hasErr = true;
      }
      return !hasErr;
    },
    getValue() {
      return {
        key: this.valueKey,
        title: this.title,
        data: this.data,
        tags: this.selectedTags.map((t) => t.tag),
      };
    },
    getKey() {
      return this.valueKey;
    },
    setValue(value, tags) {
      this.data = value;
      tags.forEach((tag) => {
        this.localTags.forEach((localTag) => {
          if (localTag.tag === tag.tag) {
            // eslint-disable-next-line no-param-reassign
            localTag.selected = true;
          }
        });
      });
    },
  },
  mounted() {
    this.localTags = this.tags.map((tag) => ({
      ...tag,
      selected: false,
    }));
  },
};
</script>

<style lang="scss" scoped>
.tags {
  &.not-clickable {
    .tag:not(.selected) {
      cursor: not-allowed;
    }
  }
  .tag {
    padding: 0px 8px;

    color: #409EFF;
    background: #ECF5FF;

    border: 1px solid #9FCEFF;
    border-radius: 4px;
    &.selected {
      color: #67C23A;
      background: #F0F9EB;
      border: 1px solid #B3E09C;
    }
    transition: all .3s ease-in-out;
  }
}
</style>
