<template lang="pug">
.income-month-page.flex.column.align-stretch
  .header.p-12
    el-button(size="mini", round, icon="el-icon-arrow-left", @click="$router.push('/income')") 返回
  .steps.flex.align-center.p-20.gap-40
    .step1.flex.align-center.gap-20
      .step 1
      .infos.flex.column.align-start.gap-4
        span 可先在下方可選取多筆資料，進行標記
        el-button(type="success", size="mini", @click="markCorrect", :disabled="!canBatchConfirm") 批次標記金額為正確
    .step2.flex.align-center.gap-20
      .step 2
      .infos.flex.column.align-start.gap-4
        span 完成標記後，即可送出資料
        el-button(
          :disabled="!canSendReuslt"
          type="primary", size="mini", @click="confirmNotificationVisible = true") 送出對帳結果
  .note
    .card-style.flex.column.m-20.p-20
      .title.bold.mb-20 提醒
      .line - 需在每月5日 23:59前送出確認，以便蛹之生核算當月薪資。逾期項目將於後續月份處理。
      .line - 每筆紀錄若標記為正確且送出之後將不能更動；標記為錯誤者，蛹之生將會與您聯絡、確認。
  .content.flex.column.p-20
    .title.font-size-20.bold.month {{ year }} 年 {{ month }} 月
    el-collapse(v-model="activeNames")
      el-collapse-item(title="自費預約", name="self")
        .table.flex
          el-table(:data="selfReserveList", max-height="500px")
            el-table-column(width="40px")
              template(slot="header", slot-scope="scope")
                .flex.align-center
                  el-checkbox(v-model="selfAllChecked")
              template(slot-scope="scope")
                el-checkbox(v-model="scope.row.checked", :disabled="scope.row.cannotChange")
            el-table-column(label="預約日期")
              template(slot-scope="scope")
                .td-time.flex.align-center
                span(:class="{'cancel-text': scope.row.isCancelled}")
                  span {{ $timestampToDateWithDay(scope.row.start_at) }}
            el-table-column(label="個案姓名")
              template(slot="header", slot-scope="scope")
                .flex.align-center
                  span 個案姓名
              template(slot-scope="scope")
                .td-name.flex.align-center.justify-space-between
                  .names.flex.align-center.wrap
                    .name.clickable(
                      @click="showCase(scope.row.user)"
                      ) {{ scope.row.user.family_name }}{{ scope.row.user.given_name }}
            el-table-column(label="分帳模組")
              template(slot-scope="scope")
                span {{ scope.row.accounting_model }}
            el-table-column(label="總金額")
              template(slot-scope="scope")
                span NT ${{ scope.row.price }}
            el-table-column(label="機構收益")
              template(slot-scope="scope")
                span {{ scope.row.organization_profit }}
            el-table-column(label="專業人士收益")
              template(slot-scope="scope")
                span {{ scope.row.professional_profit }}
            el-table-column(label="財管月", width="80px")
              template(slot-scope="scope")
                template(v-if="scope.row.payslip")
                  span {{ scope.row.payslip.year }}-{{ paddingToN(scope.row.payslip.month, 2) }}
                template(v-else)
                  span ---
            el-table-column(label="專業人士確認金額", width="150px")
              template(slot-scope="scope")
                .button-list.flex.align-center.gap-10
                  tag-toggle(
                    :disable="scope.row.cannotChange && scope.row.confirm_status !== 'confirmed'"
                    type="success", label="confirmed", v-model="scope.row.confirm_status") 正確
                  tag-toggle(
                    :disable="scope.row.cannotChange && scope.row.confirm_status !== 'wrong_amount'"
                    type="danger", label="wrong_amount", v-model="scope.row.confirm_status") 錯誤
      el-collapse-item(title="專案預約", name="project")
        .table.flex
          el-table(:cell-class-name="projectCellClassName", :data="projectReserveList", max-height="500px")
            el-table-column(width="40px")
              template(slot="header", slot-scope="scope")
                .flex.align-center
                  el-checkbox(v-model="projectAllChecked")
              template(slot-scope="scope")
                el-checkbox(v-model="scope.row.checked", :disabled="scope.row.cannotChange")
            el-table-column(label="預約日期")
              template(slot-scope="scope")
                .td-time.flex.align-center
                span {{ $timestampToDateWithDay(scope.row.start_at) }}
            el-table-column(label="個案姓名")
              template(slot="header", slot-scope="scope")
                .flex.align-center
                  span 個案姓名
              template(slot-scope="scope")
                .td-name.flex.align-center.justify-space-between
                  .names.flex.align-center.wrap
                    .name.clickable(
                      @click="showCase(scope.row.user)"
                      ) {{ scope.row.user.family_name }}{{ scope.row.user.given_name }}
            el-table-column(label="支付記錄")
              template(slot-scope="scope")
                span {{scope.row.project.organization}} - {{ scope.row.project.name }}
            el-table-column(label="總金額")
              template(slot-scope="scope")
                span NT ${{ scope.row.price }}
            el-table-column(label="分帳模組")
              template(slot-scope="scope")
                span {{ scope.row.accounting_model }}
            el-table-column(label="機構收益")
              template(slot-scope="scope")
                span {{ scope.row.organization_profit }}
            el-table-column(label="專業人士收益")
              template(slot-scope="scope")
                span {{ scope.row.professional_profit }}
            el-table-column(label="入帳")
              template(slot-scope="scope")
                span(v-if="scope.row.project.payment_received_by === 'organization'") 機構
                span(v-else) 專業人士
            el-table-column(label="專業人士實收")
              template(slot-scope="scope")
                span(v-if="scope.row.project.payment_received_by === 'organization'") {{ scope.row.professional_profit }}
                span(v-else) -{{ scope.row.organization_profit }}
            el-table-column(label="財管月", width="80px")
              template(slot-scope="scope")
                template(v-if="scope.row.payslip")
                  span {{ scope.row.payslip.year }} - {{ paddingToN(scope.row.payslip.month, 2) }}
                template(v-else)
                  span ---
            el-table-column(label="專業人士確認金額", width="150px")
              template(slot-scope="scope")
                .button-list.flex.align-center.gap-10
                  tag-toggle(
                    :disable="scope.row.cannotChange && scope.row.confirm_status !== 'confirmed'"
                    type="success", label="confirmed", v-model="scope.row.confirm_status") 正確
                  tag-toggle(
                    :disable="scope.row.cannotChange && scope.row.confirm_status !== 'wrong_amount'"
                    type="danger", label="wrong_amount", v-model="scope.row.confirm_status") 錯誤
      el-collapse-item(title="活動預約", name="activity")
        .table.flex
          el-table(:cell-class-name="activityCellClassName",:data="activityList", max-height="500px")
            el-table-column(width="40px")
              template(slot="header", slot-scope="scope")
                .flex.align-center
                  el-checkbox(v-model="activityAllChecked")
              template(slot-scope="scope")
                el-checkbox(v-model="scope.row.checked", :disabled="scope.row.cannotChange")
            el-table-column(label="活動名稱")
              template(slot-scope="scope")
                span {{ scope.row.name }}
            el-table-column(label="日期")
              template(slot-scope="scope")
                .td-time.flex.align-center
                span(:class="{'cancel-text': scope.row.isCancelled}")
                  span {{ $timestampToDateWithDay(scope.row.start_at) }}
            el-table-column(label="專業人士收益")
              template(slot-scope="scope")
                span {{ scope.row.professional_profit }}
            el-table-column(label="繳回行管費")
              template(slot-scope="scope")
                span(v-if="scope.row.professional_total_payment < 0") NT {{ scope.row.professional_total_payment }}
                span(v-else) ---
            el-table-column(label="入帳")
              template(slot-scope="scope")
                span(v-if="scope.row.payment_received_by === 'organization'") 機構
                span(v-else) 專業人士
            el-table-column(label="交通費")
              template(slot-scope="scope")
                .line.flex.column.gap-4
                  span NT $ {{ scope.row.transportation_fare?.fare || 0 }}
                  span(v-if="scope.row.transportation_fare?.received_by === 'professional'") (專業人士)
                  span(v-if="scope.row.transportation_fare?.received_by === 'organization'") (機構)
                  span(v-if="scope.row.transportation_fare?.received_by === 'paid_by_organization'") (機構自籌)
            el-table-column(label="雜費")
              template(slot-scope="scope")
                .line.flex.column.gap-4
                  span NT $ {{ scope.row.incidental_expense?.expense || 0 }}
                  span(v-if="scope.row.incidental_expense?.received_by === 'professional'") (專業人士)
                  span(v-if="scope.row.incidental_expense?.received_by === 'organization'") (機構)
                  span(v-if="scope.row.incidental_expense?.received_by === 'paid_by_organization'") (機構自籌)
            el-table-column(label="專業人士實收")
              template(slot-scope="scope")
                span NT ${{ scope.row.professional_total_payment }}
            el-table-column(label="財管月", width="80px")
              template(slot-scope="scope")
                template(v-if="scope.row.payslip")
                  span {{ scope.row.payslip.year }} - {{ paddingToN(scope.row.payslip.month, 2) }}
                template(v-else)
                  span ---
            el-table-column(label="專業人士確認金額", width="150px")
              template(slot-scope="scope")
                .button-list.flex.align-center.gap-10
                  tag-toggle(
                    :disable="scope.row.cannotChange && scope.row.confirm_status !== 'confirmed'"
                    type="success", label="confirmed", v-model="scope.row.confirm_status") 正確
                  tag-toggle(
                    :disable="scope.row.cannotChange && scope.row.confirm_status !== 'wrong_amount'"
                    type="danger", label="wrong_amount", v-model="scope.row.confirm_status") 錯誤
  el-dialog(
    title="提醒",
    width="400px"
    :visible.sync="confirmNotificationVisible"
    center
    :show-close="false"
    custom-class="no-close"
  )
    .hint.mb-40 一旦您完成送出後，標記正確的項目將無法再次修正，但若有標註錯誤的項目，您可以更新並重新提交。若您有任何疑問或需要協助，請隨時聯絡蛹之生團隊
    .flex.align-center.justify-center.gap-10
      el-button(@click="confirmNotificationVisible = false") 返回
      el-button(type="primary", @click="sendConfirm") 確定
</template>

<script>
import {
  getMonthlyAccounting,
  sendMonthlyAccountingResult,
} from '@/site/professional/api/accounting';
import TagToggle from '@/components/TagToggle.vue';
import { paddingToN } from '@/util/format';

export default {
  components: {
    'tag-toggle': TagToggle,
  },
  props: {
    year: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeNames: ['self', 'project', 'activity'],
      start: 0,
      end: 0,

      selfReserveList: [],
      projectReserveList: [],
      activityList: [],

      confirmNotificationVisible: false,

      selfAllChecked: false,
      projectAllChecked: false,
      activityAllChecked: false,
    };
  },
  computed: {
    canSendReuslt() {
      return this.selfReserveList.reduce((v, r) => (r.confirm_status === undefined ? false : v), true)
      && this.projectReserveList.reduce((v, r) => (r.confirm_status === undefined ? false : v), true)
      && this.activityList.reduce((v, r) => (r.confirm_status === undefined ? false : v), true);
    },
    canBatchConfirm() {
      return this.selfReserveList.reduce((v, r) => (r.checked || v), false)
      || this.projectReserveList.reduce((v, r) => (r.checked || v), false)
      || this.activityList.reduce((v, r) => (r.checked || v), false);
    },
  },
  watch: {
    selfAllChecked() {
      this.selfReserveList = this.selfReserveList.map((r) => ({
        ...r,
        checked: r.cannotChange ? false : this.selfAllChecked,
      }));
      this.$forceUpdate();
    },
    projectAllChecked() {
      this.projectReserveList = this.projectReserveList.map((r) => ({
        ...r,
        checked: r.cannotChange ? false : this.projectAllChecked,
      }));
      this.$forceUpdate();
    },
    activityAllChecked() {
      this.activityList = this.activityList.map((r) => ({
        ...r,
        checked: r.cannotChange ? false : this.activityAllChecked,
      }));
      this.$forceUpdate();
    },
  },
  methods: {
    paddingToN,
    markCorrect() {
      const setBatchConfirm = (list) => list.map((l) => {
        const r = { ...l };
        if (r.checked) {
          r.confirm_status = 'confirmed';
          r.checked = false;
        }
        return r;
      });
      this.selfReserveList = setBatchConfirm(this.selfReserveList);
      this.projectReserveList = setBatchConfirm(this.projectReserveList);
      this.activityList = setBatchConfirm(this.activityList);
    },
    projectCellClassName({ row, column }) {
      if (column.label === '專業人士實收') {
        if (row.project.payment_received_by === 'organization') {
          return 'positive';
        }
        return 'minus';
      }
      return '';
    },
    activityCellClassName({ row, column }) {
      if (column.label === '專業人士實收') {
        if (row.professional_total_payment >= 0) {
          return 'positive';
        }
        return 'minus';
      }
      return '';
    },
    async sendConfirm() {
      const appointments = [];
      this.selfReserveList.forEach((r) => {
        appointments.push(r);
      });
      this.projectReserveList.forEach((r) => {
        appointments.push(r);
      });
      const activities = this.activityList;
      console.log({ appointments, activities });
      this.confirmNotificationVisible = false;
      return this.$execWithLoading(async () => {
        await sendMonthlyAccountingResult(this.year, this.month, appointments, activities);
        this.$showSuccess('確認已送出');
        this.loadData();
      }, (e) => {
        this.$showAxiosException('送出確認失敗', e);
      });
    },
    setConfirm(row, value) {
      // eslint-disable-next-line no-param-reassign
      row.confirm_status = value ? 'confirmed' : 'wrong_amount';
      this.$forceUpdate();
    },
    calculateTimeRange() {
      const start = new Date(`${this.year}/${this.month}/1`);
      const end = new Date(start);
      end.setMonth(end.getMonth() + 1);
      this.start = parseInt(start.getTime() / 1000, 10);
      this.end = parseInt(end.getTime() / 1000, 10);
    },
    async loadData() {
      this.$execWithLoading(async () => {
        const rsp = await getMonthlyAccounting(this.year, this.month);
        const sortFunc = (r1, r2) => (r1.start_at > r2.start_at ? 1 : -1);
        rsp.self_paid_appointments.sort(sortFunc);
        rsp.project_paid_appointments.sort(sortFunc);
        rsp.activities.sort(sortFunc);

        this.selfReserveList = rsp.self_paid_appointments.map((a) => ({
          ...a,
          cannotChange: a.confirm_status !== 'not_confirmed',
        }));
        this.projectReserveList = rsp.project_paid_appointments.map((a) => ({
          ...a,
          cannotChange: a.confirm_status !== 'not_confirmed',
        }));
        this.activityList = rsp.activities.map((a) => ({
          ...a,
          cannotChange: a.confirm_status !== 'not_confirmed',
        }));
      });
    },
    getTimeFilter() {
      return {
        start_at: this.start,
        end_at: this.end,
      };
    },
    getSelfFilter() {
      return {
        ...this.getTimeFilter(),
        page: this.selfPage - 1,
      };
    },
    getProjectFilter() {
      return {
        ...this.getTimeFilter(),
        page: this.projectPage - 1,
      };
    },
    getActivityFilter() {
      return {
        ...this.getTimeFilter(),
        page: this.activityPage - 1,
      };
    },
  },
  mounted() {
    console.log(this.year, this.month);
    this.calculateTimeRange();
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.income-month-page {
  .header {
    background: white;
  }
}
.steps {
  flex: 0 0 100px;
  background: #E6F4F4;
  .step {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid #DCDFE6;
  }
}
</style>

<style lang="scss">
.income-month-page {
  .el-table {
    td {
      &.positive {
        background-color: #FEF0F0;
      }
      &.positive {
        background-color: #F0F9EB;
      }
    }
  }
}
</style>
