<template lang="pug">
.today.flex.align-stretch.justify-center
  .content.flex.column.overflow-overlay(ref="table")
    .title.flex.align-center
      .text.flex-1 今日行程
      el-button(type="primary" plain round @click="openCalendar") 全部行程
    .timeline.flex.align-stretch.gap-6
      .left
        .slot.flex.align-start.gap-6(v-for="hour in hours"
          :index="hour.text" :data-hour="hour.value")
          .text {{ hour.text }}
          .edge.flex.column
            .up.one-slot
            .down.one-slot
      .right
        today-block(
          @showAppointment="showDetail"
          @showProRecord="showProRecord"
          v-for="appointment in appointments"
          :data="appointment"
          :key="appointment.id")
        today-block(
          v-for="activity in activities"
          :data="activity"
          type="activity"
          :key="activity.id"
          @showActivity="showActivity")
  .calendar-container.flex.column.align-center(:class="{'show': showCalendar}")
    .header.flex.align-center
      el-button(round, size="mini", icon="el-icon-arrow-left", @click="closeCalendar") 返回
    .content.flex.flex-1.align-stretch.justify-stretch.overflow-hidden
      calendar-view(ref="calendar",
        @showPatient="showPatient", @showDetail="showDetail", @showActivity="showActivity")
  person-viewer(
    patient-mode
    simple-mode
    pro-edit
    hide-detail
    hide-open-chat
    show-open-record
    ref="personViewer"
    @showProRecord="showProRecord"
  )
  pro-person-viewer(
    @edit="goProEdit"
    ref="proPersonViewer"
  )
  appointment-detail(
    ref="appointmentDetail"
  )
  activity-viewer(
    ref="activityViewer"
  )
</template>

<script>
import { getActivities } from '@/api/activity';
import { getAppointments } from '@/api/reserve';
import TodayBlock from '@/site/professional/components/TodayBlock.vue';
import CalendarView from '@/components/AppointmentCalendarView';
import ProPersonViewer from '@/site/professional/components/PersonViewer.vue';
import PersonViewer from '@/components/drawers/PersonViewer.vue';
import AppointmentDetail from '@/site/professional/components/drawer/AppointmentDetail.vue';
import ActivityViewer from '@/site/professional/components/drawer/ActivityViewer.vue';
import { getProfile } from '@/api/auth';

export default {
  name: 'TodayPage',
  components: {
    'pro-person-viewer': ProPersonViewer,
    PersonViewer,
    TodayBlock,
    CalendarView,
    AppointmentDetail,
    ActivityViewer,
  },
  data() {
    const hours = [];
    for (let i = 0; i < 24; i += 1) {
      hours.push({
        text: `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`,
        value: i,
      });
    }
    return {
      hours,
      hour: 9,
      activities: [],
      appointments: [],

      showCalendar: false,
      profile: {},
    };
  },
  computed: {
    professionalBranch() {
      const ret = {};
      (this.profile?.branches || []).forEach((b) => {
        ret[b.id] = true;
      });
      return ret;
    },
  },
  methods: {
    showDetail(data) {
      this.$refs.appointmentDetail.$emit('show', data);
    },
    showProRecord(id) {
      this.$refs.proPersonViewer.$emit('show', {
        type: 'id',
        data: id,
      });
    },
    openCalendar() {
      this.showCalendar = true;
      this.$refs.calendar.$emit('reload');
    },
    closeCalendar() {
      this.showCalendar = false;
      this.loadData();
    },
    getFilter() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const start = new Date(now.getTime());
      const end = new Date(now.getTime() + 86400 * 1000);

      const filter = {
        start_at: parseInt(start.getTime() / 1000, 10),
        end_at: parseInt(end.getTime() / 1000, 10),
      };
      return filter;
    },
    scrollToHour(hour) {
      this.$refs.table.scrollTop = 158 * 2 * hour;
    },
    async loadProfile() {
      this.profile = await getProfile();
    },
    async loadData() {
      this.$execWithLoading(async () => {
        await this.loadProfile();
        await Promise.all([
          this.loadActivities(),
          this.loadAppointments(),
        ]);
      });
      this.scrollToHour(this.hour);
    },
    async loadActivities() {
      const data = await getActivities(this.getFilter());
      this.activities = data;
    },
    async loadAppointments() {
      const data = await getAppointments(this.getFilter());
      let reserveList = data;
      if (reserveList.appointments) {
        reserveList = reserveList.appointments.map((a) => ({
          ...a,
          need_note: !this.professionalBranch[a?.room?.branch?.id],
        }));
      }
      this.appointments = reserveList;
    },
    startProEdit(data) {
      this.$root.$emit('start-record-edit', data);
    },
    goProEdit({ start, end }) {
      this.$execWithLoading(async () => {
        const filter = this.getFilter();
        filter.start_at = start;
        filter.end_at = end;
        const datas = await getAppointments(filter);
        const data = datas.appointments.find((r) => r.start_at === start);
        if (data) {
        // this.$refs.personViewer.$emit('hide');
          this.startProEdit(data);
        }
      });
    },
    showPatient(id) {
      this.$refs.personViewer.$emit('show', {
        type: 'id',
        data: id,
      });
    },
    showActivity(data) {
      this.$refs.activityViewer.$emit('show', data);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.today {
  position: relative;
  .content {
    width: 600px;
    .title {
      flex: 0 0 auto;
      margin-top: 32px;
      .text {
        font-weight: bold;
        font-size: 20px;
      }
      position: sticky;
      top: 0;
      padding: 4px;
      z-index: 1;
      background: #F9F9F9;
    }
  }
  .timeline {
    .left {
      .slot {
        .text {
          flex: 0 0 42px;
          font-size: 12px;
          text-align: right;
        }
        .edge {
          flex: 0 0 8px;
          .one-slot {
            flex: 0 0 158px;
            border-left: 2px solid $border-base-color;
            border-top: 2px solid $border-base-color;
          }
        }
      }
    }
    .right {
      position: relative;
    }
  }
  .calendar-container {
    height: calc(100vh - #{$header-height});
    height: calc(100dvh - #{$header-height});
    width: 100vw;
    background: #F9F9F9;
    position: absolute;
    opacity: 0.01;
    transition: all .5s ease-in-out;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 2;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
    .header {
      position: sticky;
      top: 0px;
      left: 0px;
      width: 100vw;
      flex: 0 0 46px;
      background: #FFFFFF;
      box-shadow: inset 0px -1px 0px #DCDFE6;
      padding: 0 20px;
      z-index: 2;
    }
    .content {
      width: calc(100vw - 40px);
      padding: 20px;
      z-index: 1;
    }
  }
}
@media screen and (max-width: 800px) {
  .today {
    .content {
      width: 100vw;
      padding: 20px 30px;
      .title {
        margin-top: 0;
      }
    }
  }
}
</style>
