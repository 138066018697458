<template lang="pug">
.payment-month-page.flex.column.align-stretch
  .header.p-12
    el-button(size="mini", round, icon="el-icon-arrow-left", @click="$router.push('/income')") 返回
  .content.flex.column.p-20.align-center
    .detail.flex.column.align-stretch.gap-20
      .title.bold.font-size-20 {{ this.year }}年 {{ this.month }}月 薪資明細
      .card-style.flex.column.p-12.gap-10
        .title 結帳收入明細
        .card-block.flex.align-center.justify-space-between
          .left.flex.column
            .desc 自費諮商
            .text-clickable(
              @click="goDetailPage"
              v-if="payslip.range?.selfPaid"
              ) {{ payslip.range.selfPaid.startText }} - {{ payslip.range.selfPaid.endText }}
          .right NT ${{ $toCurrencyNumber(payslip.self_paid_appointment_amount) }}
        .card-block.flex.align-center.justify-space-between
          .left.flex.column
            .desc 專案收入
            .text-clickable(
              @click="goDetailPage"
              v-if="payslip.range?.projectPaid"
              ) {{ payslip.range.projectPaid.startText }} - {{ payslip.range.projectPaid.endText }}
          .right NT ${{ $toCurrencyNumber(payslip.project_paid_appointment_amount) }}
        .card-block.flex.align-center.justify-space-between
          .left.flex.column
            .desc 活動收入
            .text-clickable(
              @click="goDetailPage"
              v-if="payslip.range?.activity"
              ) {{ payslip.range.activity.startText }} - {{ payslip.range.activity.endText }}
          .right NT ${{ $toCurrencyNumber(payslip.activity_amount) }}
        .flex.align-center.justify-end.bold 小記 NT ${{ $toCurrencyNumber(payslip.work_amount) }}
      .card-style.flex.column.p-12.gap-10
        .title 其他項目
        .card-block.flex.align-center.extra-item(
          v-for="extra in payslip.extra_items"
          :class="{'positive': extra.amount >= 0, 'negative': extra.amount < 0}"
        )
          .sign {{ extra.sign }}
          .name.flex-1 {{ extra.name }}
          .value.flex.align-center
            span {{ extra.sign}}&nbsp;
            span NT ${{ $toCurrencyNumber(extra.value) }}
        .flex.align-center.justify-end.bold 小記 NT ${{ $toCurrencyNumber(payslip.professional_owed_payment + payslip.extra_payment) }}
      .card-style.flex.result-card.p-12
        .flex.column.align-start.flex-1
          .span 實發金額
          .font-size-20.bold NT$ {{ $toCurrencyNumber(payslip.amount) }}
        .flex.align-center.gap-10
          span 請確認資料是否正確
          tag-toggle(
            type="success", label="confirmed", v-model="status") 正確
          tag-toggle(
            type="danger", label="wrong_amount", v-model="status") 錯誤
      .confirm-text(v-if="status !== undefined") 請注意，若標記為正確且送出之後將不能更動；標記為錯誤者，蛹之生將會與您聯絡、確認。
      template(v-if="payslip.confirm_status === 'not_confirmed'")
        el-button(type="primary" :disabled="status === undefined", @click="sendResult") 送出對帳結果
      template(v-else)
        el-button(type="primary" disabled) 已確認當月份薪資明細
</template>

<script>
import {
  getMonthlyPayment,
  setMonthPaymentStatus,
} from '@/site/professional/api/accounting';
import TagToggle from '@/components/TagToggle.vue';

function timestampToRangeMonth(input) {
  const d = new Date(input);
  return `${d.getFullYear()} 年 ${d.getMonth() + 1} 月`;
}

export default {
  components: {
    'tag-toggle': TagToggle,
  },
  props: {
    year: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      payslip: {},
      status: undefined,
    };
  },
  methods: {
    goDetailPage() {
      this.$router.push(`/payment/${this.year}/${this.month}/detail`);
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        const rsp = await getMonthlyPayment(this.year, this.month);
        const range = {};
        if (rsp.range.self_paid_appointments.length > 0) {
          range.selfPaid = {
            startText: timestampToRangeMonth(rsp.range.self_paid_appointments[0]),
            endText: timestampToRangeMonth(rsp.range.self_paid_appointments[1]),
          };
        }
        if (rsp.range.project_paid_appointments.length > 0) {
          range.projectPaid = {
            startText: timestampToRangeMonth(rsp.range.project_paid_appointments[0]),
            endText: timestampToRangeMonth(rsp.range.project_paid_appointments[1]),
          };
        }
        if (rsp.range.activities.length > 0) {
          range.activity = {
            startText: timestampToRangeMonth(rsp.range.activities[0]),
            endText: timestampToRangeMonth(rsp.range.activities[1]),
          };
        }
        this.payslip = {
          ...rsp,
          range,
          extra_items: (rsp.extra_items || []).map((item) => ({
            ...item,
            sign: item.amount >= 0 ? '+' : '-',
            value: Math.abs(item.amount),
          })),
        };
        if (rsp.status !== 'not_confirmed') {
          this.status = this.payslip.confirm_status;
        }
      });
    },
    async sendResult() {
      return this.$execWithLoading(async () => {
        await setMonthPaymentStatus(this.year, this.month, this.status);
        this.payslip.confirm_status = this.status;
        this.$showSuccess('送出薪資結果成功');
        this.$root.$emit('check-notification');
      }, (e) => {
        this.$showAxiosException('送出薪資結果失敗', e);
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.payment-month-page {
  .header {
    background: white;
  }
  .detail {
    width: 100%;
    max-width: 600px;
    .card-block {
      background: #F5F7FA;
      padding: 12px;
      &.extra-item {
        &.positive {
          background: #E1F3D8;
        }
        &.negative {
          background: #FEF0F0;
        }
        .sign {
          flex: 0 0 20px;
        }
      }
    }
  }
  .result-card {
    background: #E6F4F4;
  }
}
</style>
