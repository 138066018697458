<template lang="pug">
el-drawer(
  title="變更雙重驗證"
  :visible.sync="drawer"
  direction="rtl"
  :wrapperClosable="false"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.align-stretch.gap-10
    .line.flex.align-center.gap-4
      .desc 請安裝
      .clickable.link-text Google Authenticator
      .desc 來完成驗證
    .line 使用你的 APP 掃描 QR code
    .line
      qr-code(
        :text="otpURL"
        :size="105"
        error-level="L")
    .splitter
    .line 輸入 APP 生成的六位數驗證至下方
    .line.flex.align-center
      el-input.flex-1(v-model="validator", @change="hasErr = false")
    .err.danger-color(v-if="hasErr") 驗證碼不正確
    el-button(
      :disabled="validator === ''"
      type="primary"
      @click="doBinding") 完成
</template>

<script>
import { mapGetters } from 'vuex';
import { verifyOTP, createOTP } from '@/api/auth';

export default {
  name: 'authenticator-setting',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      otpURL: 'oauth',
      validator: '',
      hasErr: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
      setTimeout(() => {
        this.loadQRCode();
      }, 10);
    },
    loadQRCode() {
      this.$execWithLoading(async () => {
        const otpRsp = await createOTP();
        this.secret = otpRsp.code;
        this.otpURL = `otpauth://totp/${
          this.user?.email || 'professional'
        }?secret=${
          this.secret
        }&issuer=${
          window.location.origin
        }`;
      });
    },
    async doBinding() {
      this.$execWithLoading(async () => {
        await verifyOTP(this.validator);
        this.$showSuccess('雙重驗證已更新');
        this.close(true);
      }, () => {
        this.$showError('綁定失敗，請聯絡管理員');
      });
    },
  },
  mounted() {
    this.$on('show', () => {
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
}
</style>
