<template lang="pug">
.view-part.flex.column.flex-1
  .controller.flex.align-center.gap-6.font-size-20
    .year.bold {{ year }}
    .month.bold {{ month }} 月
    el-button(icon="el-icon-arrow-left" circle, @click="prevWeek")
    el-button(icon="el-icon-arrow-right" circle, @click="nextWeek")
    el-button(round, @click="thisWeek") 今日
  .view-table.flex.column(ref="table")
    .header.flex.column
      .tr.flex.align-stretch
        .th.time &nbsp;
        .th.room.flex.align-center(
          v-for="d in dates", :key="d.text")
          span {{d.text}}
    .body.flex.column
      template(v-for="hour in hours")
        .tr.flex.align-stretch(:key="`up-${hour.value}`", :data-hour="hour.value")
          .td.time.flex.align-center {{ hour.text }}
          .td.room(v-for="d in dates", :key="d.text")
        .tr.flex.align-stretch(:key="`down-${hour.value}`")
          .td.time
          .td.room(v-for="d in dates", :key="d.text")
    template(v-for="activity in activities")
      calendar-block(:data="activity", is-activity, @showActivity="showActivity")
    template(v-for="data in reserveList")
      calendar-block(:data="data" @showPatient="showPatient", @showDetail="showDetail")
</template>

<script>
import { getAllAppointments } from '@/api/reserve';
import { getActivities } from '@/api/activity';
import AppointmentDetail from '../AppointmentDetail.vue';
import CalendarBlock from './CalendarBlock.vue';

export default {
  name: 'calendar-view',
  components: {
    'appointment-detail': AppointmentDetail,
    CalendarBlock,
  },
  props: {
    hour: {
      type: Number,
      default: 9,
    },
  },
  data() {
    const hours = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let shift = today.getDay();
    if (shift === 0) {
      shift = 7;
    }
    shift -= 1;
    const start = new Date(today.getTime() - shift * 86400 * 1000);
    console.log(start);

    const dates = this.getDatesFrom(start);
    console.log(dates);
    for (let i = 0; i < 24; i += 1) {
      hours.push({
        text: `${i % 12 === 0 ? 12 : i % 12} ${i < 12 ? 'AM' : 'PM'}`,
        value: i,
      });
    }
    return {
      reserveList: [],
      activities: [],

      filter: {},

      hours,
      dates,
    };
  },
  computed: {
    year() {
      return this.dates[0].value.getFullYear();
    },
    month() {
      return this.dates[0].value.getMonth() + 1;
    },
  },
  watch: {
    time() {
      this.changeDate();
    },
  },
  methods: {
    prevWeek() {
      const start = new Date(this.dates[0].value.getTime() - 86400 * 7 * 1000);
      this.dates = this.getDatesFrom(start);
      this.loadData();
    },
    nextWeek() {
      const start = new Date(this.dates[0].value.getTime() + 86400 * 7 * 1000);
      this.dates = this.getDatesFrom(start);
      this.loadData();
    },
    thisWeek() {
      this.dates = this.getDefaultDates();
      this.loadData();
    },
    getDefaultDates() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let shift = today.getDay();
      if (shift === 0) {
        shift = 7;
      }
      shift -= 1;
      const start = new Date(today.getTime() - shift * 86400 * 1000);
      return this.getDatesFrom(start);
    },
    getDatesFrom(start) {
      const dates = [];
      for (let i = 0; i < 7; i += 1) {
        const value = new Date(start.getTime() + 86400 * 1000 * i);
        dates.push({
          text: this.$timestampToDateWithDay(value),
          value,
        });
      }
      return dates;
    },
    getFilter() {
      const filter = {
        start_at: parseInt(this.dates[0].value.getTime() / 1000, 10),
        end_at: parseInt(this.dates[this.dates.length - 1].value.getTime() / 1000, 10),
      };
      return filter;
    },
    changeDate() {
      this.page = 1;
      this.$execWithLoading(async () => {
        await this.loadReserveList();
        await this.loadActivities();
      });
    },
    loadData() {
      this.$execWithLoading(async () => {
        await Promise.all([
          this.loadReserveList(),
          this.loadActivities(),
        ]);

        this.scrollToHour(this.hour);
      });
    },
    async loadActivities() {
      const activities = await getActivities(this.getFilter());
      this.activities = JSON.parse(JSON.stringify(activities));
    },
    async loadReserveList() {
      const reserveList = await getAllAppointments(this.getFilter());
      this.reserveList = JSON.parse(JSON.stringify(reserveList));
    },
    scrollToHour(hour) {
      this.$refs.table.scrollTop = 60 * 2 * hour;
    },
    showDetail(current) {
      this.$emit('showDetail', current);
    },
    showPatient(id) {
      this.$emit('showPatient', id);
    },
    showActivity(data) {
      this.$emit('showActivity', data);
    },
  },
  mounted() {
    this.$on('reload', () => {
      this.thisWeek();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.view-part {
  overflow: hidden;
  position: relative;
  .view-table {
    margin-top: 20px;
    background: white;
    position: relative;
    flex: 1;
    border: 1px solid $border-base-color;
    overflow-x: auto;
    overflow-x: overlay;
  }
  .tr {
    flex: 0 60px;
  }
  .header {
    position: sticky;
    top: 0;
    .tr {
      background: #E6F4F4;
      .th {
        background: #E6F4F4;
        border-right: 1px solid $border-base-color;
        &:first-child {
          position: sticky;
          left: 0;
        }
      }
    }
    z-index: 2;
  }
  .body {
    .tr {
      .td {
        border-right: 1px solid $border-base-color;
        border-top: 1px solid $border-info-color;
        background: white;
        &:first-child {
          position: sticky;
          left: 0;
          z-index: 1;
        }
      }
      &:nth-child(2n) {
        .td {
          border-top: 1px solid $border-base-color;
        }
        .td:first-child {
          border-top: none;
        }
      }
    }
  }
  .time {
    flex: 0 0 80px;
    padding: 0 16px;
  }
  .room {
    flex: 0 0 160px;
    padding: 0 16px;
  }
}
</style>
