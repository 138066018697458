export const blocks = [
  {
    title: '疾病史、評估或衡鑑結果與建議',
    key: 'medical_history',
    tags: [],
    minTagNum: 0,
    maxTagNum: 0,
    main_requirement_type: 'mental',
    require: true,
  },
  {
    title: '當事人主訴',
    key: 'main_requirement',
    tags: [],
    minTagNum: 1,
    maxTagNum: 3,
    require: true,
  },
  {
    title: '處遇重點摘要/評估',
    key: 'evaluation',
    tags: [],
    minTagNum: 1,
    maxTagNum: 1,
    require: true,
  },
  {
    title: '處置規劃',
    key: 'future_plan',
    tags: [],
    minTagNum: 1,
    maxTagNum: 2,
    require: true,
  },
  {
    title: '備注/特殊事件',
    key: 'note',
    tags: [],
    minTagNum: 0,
    maxTagNum: 0,
    require: false,
  },
];

export default {
  mental: {
    label: '心理諮詢',
    blocks,
  },
  law: {
    label: '法律諮詢',
    blocks,
  },
  finance: {
    label: '財務諮詢',
    blocks,
  },
  management: {
    label: '組織管理',
    blocks,
  },
  medical: {
    label: '健康管理',
    blocks,
  },
};

export const noProjectTags = {
  main_requirement_tags: [
    {
      tag: 'relationship',
      text: '情感議題/伴侶關係',
    },
    {
      tag: 'marriage',
      text: '婚姻與家庭',
    },
    {
      tag: 'original_family',
      text: '原生家庭',
    },
    {
      tag: 'friendship',
      text: '一般人際',
    },
    {
      tag: 'career',
      text: '職涯規劃',
    },
    {
      tag: 'colleagues',
      text: '職場人際',
    },
    {
      tag: 'pressure',
      text: '壓力管理',
    },
    {
      tag: 'emotion',
      text: '情緒議題',
    },
    {
      tag: 'trauma',
      text: '失落創傷',
    },
    {
      tag: 'psychosomatic_disorder',
      text: '身心症狀',
    },
    {
      tag: 'sexuality',
      text: '性別議題',
    },
    {
      tag: 'life',
      text: '生活適應',
    },
    {
      tag: 'self_harm',
      text: '自傷與傷人',
    },
    {
      tag: 'sex_harrassment',
      text: '性侵性騷',
    },
    {
      tag: 'child_abuse',
      text: '家暴兒虐',
    },
    {
      tag: 'drug_abuse',
      text: '藥物濫用',
    },
    {
      tag: 'politcal',
      text: '政治',
    },
    {
      tag: 'sex_gender',
      text: '性別',
    },
    {
      tag: 'culture',
      text: '文化',
    },
    {
      tag: 'tradition',
      text: '習俗',
    },
    {
      tag: 'religion',
      text: '宗教與靈性',
    },
    {
      tag: 'disability',
      text: '身心障礙',
    },
    {
      tag: 'early_treatment',
      text: '早期療育',
    },
    {
      tag: 'mental_other',
      text: '其他',
    },
  ],
  evaluation_tags: [
    {
      tag: 'mental_low_risk',
      text: '低風險：無任何立即性風險',
    },
    {
      tag: 'mental_medium_risk',
      text: '中度風險：雖有壓力但具有調管能力',
    },
    {
      tag: 'mental_high_risk',
      text: '高度風險：需藉由持續性心理治療',
    },
    {
      tag: 'mental_immediate_risk',
      text: '立即性風險：需通報、轉介相關單位或照會醫師',
    },
  ],
  future_plan_tags: [
    {
      tag: 'mental_continue',
      text: '續談',
    },
    {
      tag: 'mental_close',
      text: '結案',
    },
    {
      tag: 'mental_transfer',
      text: '轉介',
    },
    {
      tag: 'mental_present',
      text: '照會',
    },
    {
      tag: 'mental_transform_to_self_pay',
      text: '方案轉自費',
    },
    {
      tag: 'mental_future_plan_other',
      text: '其他',
    },
  ],
};
