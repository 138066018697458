<template lang="pug">
.account-page.flex.align-start.justify-center
  .card-style.content.flex.column.align-stretch.gap-10.flex-1
    .title.font-size-20.bold 個人資料
    .desc *更改資料需聯絡個管師
    .name.text-secondary 姓名
    .value {{ profile?.family_name }}{{ profile?.given_name }}
    .name.text-secondary 信箱
    .value {{ profile?.email }}
    .name.text-secondary 職稱
    .value {{ profile?.title }}
    .name.text-secondary 照片
    .value
      img.pro-img(:src="profile?.avatar")
    el-button(type="primary", @click="showPasswordSetting") 變更密碼
    el-button(type="primary", @click="showAuthenticatorSetting") 變更雙重驗證
  password-setting(ref="passwordSetting")
  authenticator-setting(ref="authenticatorSetting")
</template>

<script>
import { getProfile } from '@/api/auth';
import PasswordSetting from '@/site/professional/components/drawer/PasswordSetting.vue';
import AuthenticatorSetting from '@/site/professional/components/drawer/AuthenticatorSetting.vue';

export default {
  components: {
    PasswordSetting,
    AuthenticatorSetting,
  },
  data() {
    return {
      profile: {},
    };
  },
  methods: {
    loadData() {
      this.$execWithLoading(async () => {
        const data = await getProfile();
        this.profile = data;
      });
    },
    showPasswordSetting() {
      this.$refs.passwordSetting.$emit('show');
    },
    showAuthenticatorSetting() {
      this.$refs.authenticatorSetting.$emit('show');
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.account-page {
  padding: 20px 30px;
  background: #F3F3F3;
  .content {
    max-width: 600px;
    padding: 20px;
    .el-button {
      margin: 0;
    }
    .pro-img {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }
}
</style>
