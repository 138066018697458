<template lang="pug">
.pro-person-viewer.flex.column.align-center.overflow-overlay(:class="{'show': showEditor}")
  .header.flex.align-center
    el-button(round, size="mini", icon="el-icon-arrow-left", @click="close") 返回
  .content.flex.column.gap-10
    .name {{ data.family_name }}{{ data.given_name }}
    el-collapse
      el-collapse-item(title="個案簡歷")
        .info-block.flex.wrap.gap-10
          .info-column.flex.column.align-start(
            v-for="col in columns"
          )
            .colname {{ col.text }}
            template(v-if="col.converter")
              .value {{ col.converter(data[col.key]) }}
            template(v-else)
              .value {{ data[col.key] || '-' }}
      el-collapse-item(title="預約記錄")
        el-date-picker.search-picker(
          type="daterange"
          v-model="searchRange",
          start-placeholder="設定搜尋開始時間",
          end-placeholder="設定搜尋結束時間"
          @change="reloadRecords")
        .months.flex.column.gap-20.p-4.mt-20
          .operations.flex.align-center
            el-button(type="primary", @click="showAllMonth") 展開全部
          template(v-for="month in records")
            .month.flex.align-center.gap-4.clickable(
              :class="{'show': month.show}"
              @click="showMonth(month.month)")
              i.el-icon-date
              span {{ month.month }} ({{ month.records.length }})
            .detail.flex.column.gap-10(v-if="month.show")
              //- .link-text.clickable 下載當月所有個案記錄
              .record.card-style.flex.column.gap-8.p-8(
                :class="{'finish': record.finished_record}"
                v-for="record in month.records")
                .time {{ record.time }} ( {{ record.length }} hr )
                .desc(v-if="record.main")
                  b 主訴：
                  span {{ record.main }}
                .desc(v-if="record.evaluation")
                  b 處境重點摘要/評估：
                  span {{ record.evaluation }}
                .files.flex.column(
                  v-if="record.finished_record")
                  .file.link-text.clickable(
                    @click="goFile(file.url)"
                    v-for="file in record.records"
                    ) {{ file.filename }}
                .go-edit.link-text.clickable(
                  @click="emitEdit(record)"
                  v-else
                  ) 編輯個案記錄
</template>

<script>
import { getPatient, getProPatientAppointments } from '@/api/patient';
import { paddingToN } from '@/util/format';

export default {
  name: 'pro-person-editor',
  components: {
  },
  data() {
    const start = new Date();
    start.setDate(1);
    start.setHours(0, 0, 0, 0);
    const end = new Date(start);
    end.setMonth(start.getMonth() + 1);
    end.setTime(end.getTime() - 1);
    return {
      showEditor: false,
      data: {},
      records: {},
      searchRange: [start, end],
      columns: [
        {
          text: '生理性別',
          key: 'gender',
          converter: (key) => ({
            male: '男性',
            female: '女性',
            other: '其他',
          }[key] || '-'),
        },
        {
          text: '稱謂',
          key: 'wanna_be_called',
        },
        {
          text: '出生年月日',
          key: 'birthday',
          converter: (day) => {
            if (day && day.indexOf && day.indexOf('-') >= 0) {
              return day;
            }
            return this.$timestampToDate(day);
          },
        },
        {
          text: '身分證字號',
          key: 'id_number',
        },
        {
          text: '護照號碼',
          key: 'passport',
        },
        {
          text: '聯絡電話',
          key: 'phone',
          advanceOnly: true,
          converter: (phone) => this.$getShowPhone(phone),
        },
        {
          text: 'Email',
          key: 'email',
          advanceOnly: true,
        },
        {
          text: '感情狀態',
          key: 'relationship',
          converter: (key) => this.$tryMsg(`appointment.relationship.${key}`) || '未知',
        },
        {
          text: '最高學歷',
          key: 'education',
          converter: (key) => this.$tryMsg(`appointment.education.${key}`) || '未知',
        },
        {
          text: '產業',
          key: 'industry',
        },
        {
          text: '職業',
          key: 'job',
        },
        {
          text: '語言',
          key: 'prefer_language',
        },
        {
          text: '案件來源',
          key: 'refer_source',
        },
        {
          text: '就醫紀錄/諮商經驗',
          key: 'therapy_history',
        },
        {
          text: '是否嘗試過自殺',
          key: 'suicide_record',
        },
      ],
    };
  },
  methods: {
    getPatient,
    getPatientAppointments: getProPatientAppointments,
    emitEdit(record) {
      this.$emit('edit', record);
    },
    goFile(url) {
      window.open(url, '_blank');
    },
    reset() {
      this.showEditor = false;
      this.data = {};
      this.records = {};
    },
    close() {
      this.showEditor = false;
    },
    async reloadRecords() {
      return this.$execWithLoading(async () => {
        await this.reloadRecordsWithoutLoading();
      });
    },
    async reloadRecordsWithoutLoading() {
      const { id } = this.data;
      const start = parseInt(this.searchRange[0].getTime() / 1000, 10);
      const end = parseInt(this.searchRange[1].getTime() / 1000, 10) + 86400;
      const datas = await this.getPatientAppointments(id, start, end);
      this.parseRecords(datas, start, end);
    },
    async show(id) {
      this.showEditor = true;
      setTimeout(async () => {
        this.$execWithLoading(async () => {
          const user = await this.getPatient(id);
          this.data = user;
          await this.reloadRecordsWithoutLoading();
        });
      }, 100);
    },
    parseRecords(datas, start, end) {
      const monthMap = {};
      const useData = datas.filter((d) => d.start_at > start && d.start_at < end);
      useData.forEach((data) => {
        const endTime = new Date(data.end_at * 1000);
        const year = endTime.getFullYear();
        const month = endTime.getMonth() + 1;
        const key = `${year}/${paddingToN(month, 2)}`;
        if (!monthMap[key]) {
          monthMap[key] = [];
        }
        const pushData = {
          id: data.id,
          finished_record: data.finished_record,
          start: data.start_at,
          end: data.end_at,
          length: ((data.end_at - data.start_at) / 3600).toFixed(1),
          time: `${
            this.$timestampToDateTimeMinute(data.start_at)
          } ${
            this.$timestampToM(data.start_at)
          }`,
          records: data.records,
        };
        if (data.records.length > 0) {
          const record = data.records[0];
          pushData.main = record.main_requirement;
          pushData.evaluation = record.evaluation;
          if (pushData.main.length >= 100) {
            pushData.main += '...';
          }
          if (pushData.evaluation.length >= 100) {
            pushData.evaluation += '...';
          }
        }
        monthMap[key].push(pushData);
      });
      const results = Object.keys(monthMap).map((month) => {
        monthMap[month].sort((a, b) => (a.time > b.time ? 1 : -1));
        return {
          show: false,
          month,
          records: monthMap[month],
        };
      });
      results.sort((a, b) => (a.month > b.month ? -1 : 1));
      if (results.length > 0) {
        results[0].show = true;
      }
      this.records = results;
    },
    showAllMonth() {
      this.records.forEach((record) => {
        // eslint-disable-next-line no-param-reassign
        record.show = true;
      });
      this.$forceUpdate();
    },
    showMonth(month) {
      this.records.forEach((record) => {
        // eslint-disable-next-line no-param-reassign
        record.show = (record.month === month);
      });
      this.$forceUpdate();
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.show(data.data);
    });
    this.$on('hide', () => {
      this.reset();
    });
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.pro-person-viewer {
  height: calc(100vh - #{$header-height});
  height: calc(100dvh - #{$header-height});
  width: 100vw;
  background: #F9F9F9;
  position: absolute;
  opacity: 0.01;
  transition: all .5s ease-in-out;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 2;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  .header {
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100vw;
    flex: 0 0 46px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #DCDFE6;
    padding: 0 20px;
  }
  .content {
    width: calc(100vw - 40px);
    max-width: 600px;
    padding: 20px;
    margin-top: 30px;
    .name {
      font-size: 20px;
      color: #414345;
    }
  }
}

.info-block {
    overflow: auto;
    overflow: overlay;
    .info-column {
      flex: 0 0 calc(50% - 20px);
      .colname {
        font-size: 12px;
        color: $text-secondary-color;
      }
      .value {
        color: $text-regular-color;
      }
    }
  }

.month {
  padding: 4px 12px;
  height: 32px;
  background: white;
  border: 1px solid #D6D6D6;
  border-radius: 4px;
  &.show {
    border: 1px solid #409EFF;
  }
}
.detail {
  .record {
    background: #FEF0F0;
    &.finish {
      background: #ECF5FF;
    }
  }
}
.search-picker {
  width: 100% !important;
}
</style>
