/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: undefined,
  },
  mutations: {
    setUserInfo(s, user) {
      s.user = user;
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    userDisplayName: (s) => {
      if (s.user?.family_name && s.user?.given_name) {
        return `${s.user.family_name}${s.user.given_name}`;
      }
      return '使用者姓名';
    },
    isLogin: (s) => s.user !== undefined,
  },
});
