<template lang="pug">
.payment-month-page.flex.column.align-stretch
  .header.flex.align-center.p-12.gap-20
    el-button(size="mini", round, icon="el-icon-arrow-left", @click="$router.back()") 返回
  .content.flex.column.p-20
    .title.font-size-24.bold.mb-12 財管月: {{ year }} 年 {{ month }} 月
    el-collapse(v-model="activeNames")
      el-collapse-item(title="自費諮商", name="self")
        .table.flex
          el-table(:data="selfReserveList", max-height="500px")
            el-table-column(label="預約日期")
              template(slot-scope="scope")
                .td-time.flex.align-center
                span(:class="{'cancel-text': scope.row.isCancelled}")
                  span {{ $timestampToDateWithDay(scope.row.start_at) }}
            el-table-column(label="個案姓名")
              template(slot="header", slot-scope="scope")
                .flex.align-center
                  span 個案姓名
              template(slot-scope="scope")
                .td-name.flex.align-center.justify-space-between
                  .names.flex.align-center.wrap
                    .name.clickable(
                      @click="showCase(scope.row.user)"
                      ) {{ scope.row.user.family_name }}{{ scope.row.user.given_name }}
            el-table-column(label="分帳模組")
              template(slot-scope="scope")
                span {{ scope.row.accounting_model }}
            el-table-column(label="總金額")
              template(slot-scope="scope")
                span NT ${{ $toCurrencyNumber(scope.row.price) }}
            el-table-column(label="機構收益")
              template(slot-scope="scope")
                span {{ $toCurrencyNumber(scope.row.organization_profit) }}
            el-table-column(label="專業人士收益")
              template(slot-scope="scope")
                span {{ $toCurrencyNumber(scope.row.professional_profit) }}
        .total.bold.pt-12.flex.align-center.gap-4.justify-end
          span 小計
          span {{ $toCurrencyNumber(selfTotal) }}
      el-collapse-item(title="專案收入", name="project")
        .table.flex
          el-table(:cell-class-name="projectCellClassName", :data="projectReserveList", max-height="500px")
            el-table-column(label="預約日期")
              template(slot-scope="scope")
                .td-time.flex.align-center
                span {{ $timestampToDateWithDay(scope.row.start_at) }}
            el-table-column(label="個案姓名")
              template(slot="header", slot-scope="scope")
                .flex.align-center
                  span 個案姓名
              template(slot-scope="scope")
                .td-name.flex.align-center.justify-space-between
                  .names.flex.align-center.wrap
                    .name.clickable(
                      @click="showCase(scope.row.user)"
                      ) {{ scope.row.user.family_name }}{{ scope.row.user.given_name }}
            el-table-column(label="支付記錄")
              template(slot-scope="scope")
                span {{scope.row.project.organization}} - {{ scope.row.project.name }}
            el-table-column(label="總金額")
              template(slot-scope="scope")
                span NT ${{ $toCurrencyNumber(scope.row.price) }}
            el-table-column(label="分帳模組")
              template(slot-scope="scope")
                span {{ scope.row.accounting_model }}
            el-table-column(label="機構收益")
              template(slot-scope="scope")
                span {{ $toCurrencyNumber(scope.row.organization_profit) }}
            el-table-column(label="專業人士收益")
              template(slot-scope="scope")
                span {{ $toCurrencyNumber(scope.row.professional_profit) }}
            el-table-column(label="入帳")
              template(slot-scope="scope")
                span(v-if="scope.row.project.payment_received_by === 'organization'") 機構
                span(v-else) 專業人士
            el-table-column(label="專業人士實收")
              template(slot-scope="scope")
                span(v-if="scope.row.project.payment_received_by === 'organization'") {{ scope.row.professional_profit }}
                span(v-else) -{{ scope.row.organization_profit }}
        .total.bold.pt-12.flex.align-center.gap-4.justify-end
          span 小計
          span {{ $toCurrencyNumber(projectTotal) }}
      el-collapse-item(title="活動收入", name="activity")
        .table.flex
          el-table(:cell-class-name="activityCellClassName",:data="activityList", max-height="500px")
            el-table-column(label="活動名稱")
              template(slot-scope="scope")
                span {{ scope.row.name }}
            el-table-column(label="日期")
              template(slot-scope="scope")
                .td-time.flex.align-center
                span(:class="{'cancel-text': scope.row.isCancelled}")
                  span {{ $timestampToDateWithDay(scope.row.start_at) }}
            el-table-column(label="專業人士收益")
              template(slot-scope="scope")
                span {{ $toCurrencyNumber(scope.row.professional_profit) }}
            el-table-column(label="繳回行管費")
              template(slot-scope="scope")
                span(v-if="scope.row.professional_total_payment < 0") NT {{ $toCurrencyNumber(scope.row.professional_total_payment) }}
                span(v-else) ---
            el-table-column(label="入帳")
              template(slot-scope="scope")
                span(v-if="scope.row.payment_received_by === 'organization'") 機構
                span(v-else) 專業人士
            el-table-column(label="交通費")
              template(slot-scope="scope")
                .line.flex.column.gap-4
                  span NT $ {{ $toCurrencyNumber(scope.row.transportation_fare?.fare || 0) }}
                  span(v-if="scope.row.transportation_fare?.received_by === 'professional'") (專業人士)
                  span(v-if="scope.row.transportation_fare?.received_by === 'organization'") (機構)
                  span(v-if="scope.row.transportation_fare?.received_by === 'paid_by_organization'") (機構自籌)
            el-table-column(label="雜費")
              template(slot-scope="scope")
                .line.flex.column.gap-4
                  span NT $ {{ $toCurrencyNumber(scope.row.incidental_expense?.expense || 0) }}
                  span(v-if="scope.row.incidental_expense?.received_by === 'professional'") (專業人士)
                  span(v-if="scope.row.incidental_expense?.received_by === 'organization'") (機構)
                  span(v-if="scope.row.incidental_expense?.received_by === 'paid_by_organization'") (機構自籌)
            el-table-column(label="專業人士實收")
              template(slot-scope="scope")
                span NT ${{ $toCurrencyNumber(scope.row.professional_total_payment) }}
        .total.bold.pt-12.flex.align-center.gap-4.justify-end
          span 小計
          span {{ $toCurrencyNumber(activityTotal) }}
</template>

<script>
import {
  getMonthlyPaymentDetail,
} from '@/site/professional/api/accounting';
import TagToggle from '@/components/TagToggle.vue';
import { paddingToN } from '@/util/format';

export default {
  components: {
    'tag-toggle': TagToggle,
  },
  props: {
    year: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeNames: ['self', 'project', 'activity'],
      start: 0,
      end: 0,

      selfReserveList: [],
      projectReserveList: [],
      activityList: [],
      selfTotal: 0,
      projectTotal: 0,
      activityTotal: 0,

      confirmNotificationVisible: false,
    };
  },
  methods: {
    paddingToN,
    projectCellClassName({ row, column }) {
      if (column.label === '專業人士實收') {
        if (row.project.payment_received_by === 'organization') {
          return 'positive';
        }
        return 'minus';
      }
      return '';
    },
    activityCellClassName({ row, column }) {
      if (column.label === '專業人士實收') {
        if (row.professional_total_payment >= 0) {
          return 'positive';
        }
        return 'minus';
      }
      return '';
    },
    setConfirm(row, value) {
      // eslint-disable-next-line no-param-reassign
      row.confirm_status = value ? 'confirmed' : 'wrong_amount';
      this.$forceUpdate();
    },
    calculateTimeRange() {
      const start = new Date(`${this.year}/${this.month}/1`);
      const end = new Date(start);
      end.setMonth(end.getMonth() + 1);
      this.start = parseInt(start.getTime() / 1000, 10);
      this.end = parseInt(end.getTime() / 1000, 10);
    },
    async loadData() {
      this.$execWithLoading(async () => {
        const rsp = await getMonthlyPaymentDetail(this.year, this.month);
        const sortFunc = (r1, r2) => (r1.start_at > r2.start_at ? 1 : -1);
        rsp.self_paid_appointments.sort(sortFunc);
        rsp.project_paid_appointments.sort(sortFunc);
        rsp.activities.sort(sortFunc);

        this.selfReserveList = rsp.self_paid_appointments.map((a) => ({
          ...a,
          cannotChange: a.confirm_status !== 'not_confirmed',
        }));
        this.projectReserveList = rsp.project_paid_appointments.map((a) => ({
          ...a,
          cannotChange: a.confirm_status !== 'not_confirmed',
        }));
        this.activityList = rsp.activities.map((a) => ({
          ...a,
          cannotChange: a.confirm_status !== 'not_confirmed',
        }));
        this.selfTotal = this.selfReserveList.reduce((v, l) => v + l.professional_profit, 0);
        this.projectTotal = this.projectReserveList.reduce((v, l) => {
          if (l.project.payment_received_by === 'organization') {
            return v + l.professional_profit;
          }
          return v - l.organization_profit;
        }, 0);
        this.activityTotal = this.activityList.reduce((v, l) => v + l.professional_total_payment, 0);
      });
    },
    getTimeFilter() {
      return {
        start_at: this.start,
        end_at: this.end,
      };
    },
    getSelfFilter() {
      return {
        ...this.getTimeFilter(),
        page: this.selfPage - 1,
      };
    },
    getProjectFilter() {
      return {
        ...this.getTimeFilter(),
        page: this.projectPage - 1,
      };
    },
    getActivityFilter() {
      return {
        ...this.getTimeFilter(),
        page: this.activityPage - 1,
      };
    },
  },
  mounted() {
    this.calculateTimeRange();
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.payment-month-page {
  .header {
    background: white;
  }
}
.steps {
  flex: 0 0 100px;
  background: #E6F4F4;
  .step {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid #DCDFE6;
  }
}
</style>

<style lang="scss">
.payment-month-page {
  .el-table {
    td {
      &.positive {
        background-color: #FEF0F0;
      }
      &.positive {
        background-color: #F0F9EB;
      }
    }
  }
}
</style>
