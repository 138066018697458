<template lang="pug">
#app.safari-wrap.flex.column
  template(v-if="ready")
    .header.flex(:class="{'show': isLogin}")
      el-tooltip(v-if="version", placement="bottom", :content="version")
        .logo(@click="$copy(version, true)")
      .logo(v-else)
      el-menu.menu(mode="horizontal", :default-active="menu", @select="changePage")
        el-menu-item(index="Today")
          a.link(href="/#/today", @click="stopLink") 今日行程
        el-menu-item(index="Appointment")
          a.link(href="/#/appointment", @click="stopLink") 諮商預約
        el-menu-item(index="Activity")
          a.link(href="/#/activity", @click="stopLink") 活動預約
        el-menu-item(index="Income")
          a.link(href="/#/incoume", @click="stopLink")
            .with-notification 營收資訊
              template(v-if="showConfirmNotify")
                .notification-dot
        el-menu-item(index="Account")
          a.link(href="/#/account", @click="stopLink") 帳號管理
      .blank.rwd-only.flex-1
      .user.flex.align-center.gap-12
        i.el-icon-user-solid
        .username {{ userDisplayName }}
      .logout.flex.align-center.clickable(@click="logout") 登出
      el-dropdown.menu-icon.flex.align-center.justify-center.font-size-14(
        @command="changePage"
      )
        i.el-icon-menu.rwd-only
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item(command="Today")
            .dropdown-name 今日行程
          el-dropdown-item(command="Appointment")
            .dropdown-name 諮商預約
          el-dropdown-item(command="Activity")
            .dropdown-name 活動預約
          el-dropdown-item(command="Income")
            .dropdown-name 營收資訊
          el-dropdown-item(command="Account")
            .dropdown-name 帳號管理
          el-dropdown-item(command="Logout")
            .dropdown-name 登出
    router-view.content(v-if="ready", ref="page")
  user-viewer.user-viewer(
    :class="{'show': detailUser !== undefined}"
    @close="detailUser = undefined"
    :uid="detailUser?.id")
  record-editor(ref="recordEditor", @finish="doFinish")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getProfile } from '@/api/auth';
import {
  GetNotConfirmedPayments,
} from '@/site/professional/api/accounting';
import UserViewer from '@/components/UserViewer.vue';
import RecordEditor from '@/site/professional/components/RecordEditor.vue';
import router from './router';

export default {
  components: {
    'user-viewer': UserViewer,
    'record-editor': RecordEditor,
  },
  data() {
    return {
      menu: 'Home',
      ready: false,
      detailUser: undefined,
      version: undefined,
      showConfirmNotify: false,
    };
  },
  computed: {
    ...mapGetters(['userDisplayName', 'isLogin']),
  },
  watch: {
    $route() {
      this.detailUser = undefined;
      this.checkRoute();
    },
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    doFinish() {
      console.log(this.$refs.page);
      if (this.$refs.page?.refreshData) {
        this.$refs.page.refreshData();
      }
    },
    stopLink(e) {
      e.preventDefault();
    },
    logout() {
      this.setUserInfo(undefined);
      this.$router.push('/login');
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        const profile = await getProfile();
        this.setUserInfo(profile);
        await this.checkNotChecked();
      });
    },
    async checkNotChecked() {
      const notConfirmed = await GetNotConfirmedPayments();
      console.log(notConfirmed, notConfirmed.length);
      this.showConfirmNotify = notConfirmed.length > 0;
    },
    changePage(key) {
      if (key === 'Logout') {
        this.logout();
        return;
      }
      this.$router.push(`/${key.toLowerCase()}`).catch(this.handleDuplicatedRoute);
    },
    checkRoute() {
      router.forEach((r) => {
        let find = false;
        if (r.name === this.$route.name) {
          find = true;
        }
        if (!find && r.children !== undefined) {
          r.children.forEach((child) => {
            if (child.name === this.$route.name) {
              find = true;
            }
          });
        }
        if (find) {
          this.menu = r.name;
          if (r.needAuth && !this.isLogin) {
            this.$router.push('/login');
          }
        }
      });
    },
    handleDuplicatedRoute(error) {
      if (error.name !== 'NavigationDuplicated') {
        throw error;
      }
      this.ready = false;
      setTimeout(() => {
        this.ready = true;
        this.$forceUpdate();
      }, 100);
    },
  },
  async mounted() {
    function safariHacks() {
      const windowsVH = window.innerHeight / 100;
      document.querySelector('.safari-wrap').style.setProperty('--vh', `${windowsVH}px`);
      window.addEventListener('resize', () => {
        document.querySelector('.safari-wrap').style.setProperty('--vh', `${windowsVH}px`);
      });
    }

    window.addEventListener('resize', () => {
      safariHacks();
    });

    this.$root.$on('show-user', (user) => {
      this.detailUser = user;
    });
    this.$root.$on('start-record-edit', (data) => {
      this.$refs.recordEditor.$emit('show', data);
    });
    this.$root.$on('check-notification', () => {
      this.checkNotChecked();
    });
    this.$root.$on('show-notification', () => {
      this.showConfirmNotify = true;
    });

    this.version = await this.$getVersion();

    await this.loadData();
    this.checkRoute();
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-regular-color;
  font-size: 14px;
  .header {
    background: #5AB0AC;
    max-height: 0;
    transition: transform .3s ease-in-out;
    border-bottom: solid 1px #e6e6e6;
    overflow: hidden;
    color: white;

    &.show {
      max-height: $header-height;
    }
    .logo {
      box-sizing: border-box;
      flex: 0 0 240px;
      background-image: url('/white-logo-long.png');
      background-position: 50% 50%;
      background-size: 200px 32px;
      background-repeat: no-repeat;
      border-right: 1px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
    .menu {
      flex: 1;
      border: none;
      background: #5AB0AC;
      .link {
        text-decoration: none;
      }
    }
    .user {
      padding: 0 20px;
      color: white;
    }
    .logout {
      padding: 0 20px;
    }
    .menu-icon {
      padding: 0 20px;
      color: white;
    }
  }
  .content {
    flex: 1;
    overflow-x: hidden;
    background: #F9F9F9;
  }
  .user-viewer {
    position: absolute;
    opacity: 0.01;
    transition: all .5s ease-in-out;
    pointer-events: none;
    top: $header-height;
    left: 0;
    z-index: 2;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
  .with-notification {
    position: relative;
    .notification-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: rgb(196, 86, 86);
      position: absolute;
      top: calc(50% - 10px);
      right: -10px;
      box-shadow:0 0 2px 1px rgb(196, 86, 86);
    }
  }
}

@media screen and (max-width: 800px) {
  #app {
    .header {
      .logo {
        border-right: none;
      }
      .menu {
        display: none;
      }
      .user {
        display: none;
      }
      .logout {
        display: none;
      }
    }
  }
  .dropdown-name {
    width: 250px;
  }
}
</style>
