<template lang="pug">
el-drawer(
  title="變更密碼"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody"
  :wrapperClosable="false")
  .drawer.flex.column.align-stretch.gap-10
    .col.flex.column.align-stretch.gap-4
      .name 舊密碼
      .value.flex.align-center
        el-input.flex-1(
          type="password"
          v-model="origPwd")
      .err.danger-color(v-if="errOld") 舊密碼不符
    .col.flex.column.align-stretch.gap-4
      .name 新密碼
      .value.flex.align-center
        el-input.flex-1(
          type="password"
          v-model="newPwd")
    .col.flex.column.align-stretch.gap-4
      .name 重複輸入新密碼
      .value.flex.align-center
        el-input.flex-1(
          type="password"
          v-model="newPwdRepeat")
      .err.danger-color(v-if="errNew") 新密碼不符
    el-button(
      :disabled="!canSave"
      type="primary"
      @click="save") 完成
</template>

<script>
import { updateProfile } from '@/api/auth';

export default {
  name: 'password-setting',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      origPwd: '',
      newPwd: '',
      newPwdRepeat: '',
      drawer: false,
      errOld: false,
    };
  },
  computed: {
    errNew() {
      return this.newPwd !== '' && this.newPwdRepeat !== '' && this.newPwd !== this.newPwdRepeat;
    },
    canSave() {
      return this.origPwd !== '' && this.newPwd !== '' && !this.errNew;
    },
  },
  methods: {
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
    },
    save() {
      this.$execWithLoading(async () => {
        await updateProfile(this.origPwd, this.newPwd);
        this.$showSuccess('密碼已更新');
        this.reset();
        this.close(true);
      }, (e) => {
        this.$showAxiosException(e);
      });
    },
    reset() {
      this.origPwd = '';
      this.newPwd = '';
      this.newPwdRepeat = '';
      this.drawer = false;
      this.errOld = false;
    },
  },
  mounted() {
    this.$on('show', () => {
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
}
</style>
