<template lang="pug">
.reserve-page.flex.column.gap-20
  .search-bar.flex.align-center.justify-space-between
    .filter.flex.align-end.gap-10
      .column.flex.column.align-start.gap-4
        .title 預約時間
        el-date-picker.date-range-input(@change="changeDate"
          v-model="times"
          type="daterange"
          range-separator="至")
      .column.flex.column.align-start.gap-4
        .title 館別
        place-selector.place-input(
          show-all
          v-model="branch", @input="changeBranch")
  template(v-if="reserveList.length > 0")
    .table.flex
      el-table(:data="reserveList", :row-class-name="tableRowClassName")
        el-table-column(label="個案姓名")
          template(slot="header", slot-scope="scope")
            .flex.align-center
              template(v-if="userSearch")
                user-picker(
                  pro-mode
                  size="mini", @clear="setUserSearch(undefined)", @input="setUserSearch")
              template(v-else)
                span 個案姓名
                i.el-icon-search.clickable(@click="userSearch = true")
          template(slot-scope="scope")
            .td-name.flex.align-center.justify-space-between
              .names.flex.align-center.wrap
                .name.clickable(@click="showCase(scope.row.user)"
                  ) {{ scope.row.user.family_name }}{{ scope.row.user.given_name }}
                template(v-if="scope.row.appointment_type !== 'parent'")
                  template(v-for="(p, idx) in scope.row.partners")
                    span(:key="`dot-${idx}`") 、
                    .name.clickable(
                      :key="p.id", @click="showCase(p)"
                      ) {{ p.family_name }}{{ p.given_name }}
                template(v-else)
                  template(v-for="(p, idx) in scope.row.partners")
                    .name.clickable.ml-4(
                      :key="p.id", @click="showCase(p)"
                      )
                      span (
                      template(v-if="scope.row.appointment_subtype === 'statutoryagents'")
                        span 法代
                        span :
                      template(v-else-if="scope.row.appointment_subtype === 'children'")
                        span 子女
                        span :
                      span {{ p.family_name }}{{ p.given_name }}
                      span )
              .note(v-if="scope.row.note")
                el-tooltip(:content="scope.row.note", placement="right")
                  i.el-icon-chat-dot-round.clickable
        el-table-column(label="預約時間與時長")
          template(slot-scope="scope")
            .td-time.flex.align-center
            span(:class="calculateCancelClass(scope.row)")
              span {{ $timestampToDateTimeMinuteWithDay(scope.row.start_at) }}
            span(:class="calculateCancelClass(scope.row)") &nbsp;({{ scope.row.length }} hr)
        el-table-column(label="預約型態")
          template(slot-scope="scope")
            span(
              v-if="scope.row.location_type === 'inhouse'"
              :class="calculateCancelClass(scope.row)"
              ) 實體諮商
            .clickable.link-text.flex.align-center(
              v-else
              @click="goLink(scope.row)"
              :class="calculateCancelClass(scope.row)")
              span 遠距諮商
              i.el-icon-link
        el-table-column(label="預約空間")
          template(slot-scope="scope")
            span(:class="calculateCancelClass(scope.row)") {{ scope.row.room.branch.name }}
            span(:class="calculateCancelClass(scope.row)") &nbsp;{{ scope.row.room.name }}
        el-table-column(label="預約價位")
          template(slot-scope="scope")
            span(:class="calculateCancelClass(scope.row)") NT ${{ scope.row.price }}
        el-table-column(label="專案")
          template(slot-scope="scope")
            span(:class="calculateCancelClass(scope.row)")
              template {{ scope.row.project_organization }} -&nbsp;
              template {{ scope.row.project }}
        el-table-column(label="支援報備")
          template(slot-scope="scope")
            template(v-if="scope.row.need_note")
              el-checkbox(
                size="small"
                v-model="scope.row.noted", @click.native="checkNoted(scope.row, $event)", label="需支援報備" border)
            template(v-else)
              span --
        el-table-column(label="個案記錄")
          template(slot-scope="scope")
            .flex.align-center.gap-4
              img(v-if="scope.row.record_finished" :src="successLogo")
              .clickable.link-text(@click="startEdit(scope.row)") 編輯
    .pagination.flex.align-center.justify-end
      el-pagination(
        layout="total, sizes, prev, pager, next, ->, jumper"
        :page-size.sync="limit"
        :current-page.sync="currentPage"
        :total="total"
      )
  .empty(v-else) 此篩選無任何預約記錄
  pro-person-viewer(
    @edit="goEdit"
    ref="proPersonViewer"
  )
  person-viewer(
    patient-mode
    simple-mode
    pro-edit
    hide-detail
    hide-open-chat
    show-open-record
    ref="personViewer"
    @showProRecord="showProRecord"
  )
</template>

<script>
import {
  getAppointments,
  updateAppointmentTags,
  cancelAppointment,
  setAppointmentNoted,
} from '@/api/reserve';
import { getProfile } from '@/api/auth';
import successLogo from '@/assets/logo/success.svg';
import AppointmentNew from '@/components/drawers/AppointmentNew.vue';
import AppointmentEditor from '@/components/drawers/AppointmentEdit.vue';
import ProPersonViewer from '@/site/professional/components/PersonViewer.vue';
import PersonViewer from '@/components/drawers/PersonViewer.vue';
import { getOrganizations } from '@/api/project';

export default {
  components: {
    'pro-person-viewer': ProPersonViewer,
    'person-viewer': PersonViewer,
    'appointment-edit': AppointmentEditor,
    'appointment-new': AppointmentNew,
  },
  data() {
    const now = new Date();

    return {
      times: [],
      branch: undefined,
      reserveList: [],
      tags: [],

      userFilter: undefined,
      proFilter: undefined,
      userSearch: false,
      proSearch: false,

      total: 10,
      currentPage: 1,
      limit: 100,
      filter: {},

      successLogo,
      detailUser: undefined,

      editBoundary: parseInt(now.getTime() / 1000, 10) - 86400,
      profile: {},
    };
  },
  watch: {
    currentPage() {
      this.loadData();
    },
    limit() {
      this.loadData();
    },
  },
  computed: {
    professionalBranch() {
      const ret = {};
      (this.profile?.branches || []).forEach((b) => {
        ret[b.id] = true;
      });
      return ret;
    },
  },
  methods: {
    async loadProfile() {
      this.profile = await getProfile();
    },
    calculateCancelClass(data) {
      return {
        'cancel-text': data.isCancelled,
      };
    },
    checkNoted(data, ev) {
      ev.stopPropagation();
      ev.preventDefault();
      console.log(data);
      this.$execWithLoading(async () => {
        await setAppointmentNoted(data.id, !data.noted);
        this.$showSuccess(!data.noted ? '已設定完成報備' : '已取消完成報備');
        // eslint-disable-next-line no-param-reassign
        data.noted = !data.noted;
      }, (e) => {
        console.log(e);
        this.$showAxiosException('設定報備狀態失敗', e);
      });
    },
    refreshData() {
      this.loadData();
    },
    goLink(appointment) {
      if (appointment.location_type === 'virtual' && appointment.payment_method === 'notpaid') {
        this.$alert('此預約尚未完成付款，唔談空間暫不開放', '提醒', {
          confirmButtonText: '知道了',
        });
        return;
      }
      this.$goLink(appointment.meeting_link);
    },
    tableRowClassName(scope) {
      const { row } = scope;
      if (!row.project && row.location_type === 'virtual'
       && (row.payment_method === undefined || row.payment_method === 'notpaid')) {
        return 'notpaid';
      }
      return '';
    },
    showProRecord(id) {
      this.$refs.proPersonViewer.$emit('show', {
        type: 'id',
        data: id,
      });
    },
    editProSearch() {
      this.proSearch = true;
      this.$nextTick(() => {
        this.$refs.proPicker.$emit('focus');
      });
    },
    execCommand(cmd, appointment) {
      console.log({ cmd, appointment });

      if (cmd === 'edit') {
        this.$refs.appointmentEdit.$emit('show', appointment);
      } else if (cmd === 'cancel') {
        this.cancelAppointment(appointment);
      }
    },
    async cancelAppointment(appointment) {
      this.$confirm('確定要取消此預約嗎').then(
        () => this.$execWithLoading(async () => {
          await cancelAppointment(appointment.id);
          this.$showSuccess('已取消預約');
          this.loadData();
        }),
      ).catch(() => {});
    },
    showDetail(user) {
      this.$root.$emit('show-user', user);
    },
    paidCanChange(payment) {
      return ['notpaid', 'cash', 'transfer'].indexOf(payment) >= 0;
    },
    showCase(data) {
      this.$refs.personViewer.$emit('show', {
        type: 'id',
        data: data.id || data.user.id,
      });
    },
    getFilter() {
      if (!this.times || this.times.length === 0) {
        this.times = this.getDefaultTime();
      }
      const [start, end] = this.times;
      end.setHours(23, 59, 59, 999);
      const filter = {
        start_at: parseInt(start.getTime() / 1000, 10),
        end_at: parseInt(end.getTime() / 1000, 10),
        limit: this.limit,
        branch: (this.branch !== '' && this.branch) ? this.branch : undefined,
        page: this.currentPage - 1,
      };
      if (this.userFilter) {
        filter.user = this.userFilter;
      }
      if (this.proFilter) {
        filter.professional = this.proFilter;
      }
      return filter;
    },
    changeDate() {
      this.page = 1;
      this.$execWithLoading(async () => {
        await this.loadReserveList();
      });
    },
    loadData() {
      getOrganizations();
      this.$execWithLoading(async () => {
        await this.loadProfile();
        await this.loadReserveList();
      });
    },
    async loadReserveList() {
      const now = new Date();
      const nowTs = parseInt(now.getTime() / 1000, 10);

      let reserveList = await getAppointments(this.getFilter());
      const { meta } = reserveList;
      if (reserveList.appointments) {
        reserveList = reserveList.appointments;
        this.total = meta.total || 0;
      }
      this.reserveList = reserveList.filter((r) => r.status !== 'cancelled').map((r) => ({
        ...r,
        user: r.patient,
        inFuture: r.start_at > nowTs,
        length: ((r.end_at - r.start_at) / 3600).toFixed(1),
        isCancelled: r.status === 'cancelled',
        need_note: !this.professionalBranch[r?.room?.branch?.id],
      }));
    },
    async updateBranchTags(branch) {
      return this.$execWithLoading(async () => {
        await updateAppointmentTags(branch.id, branch.tags);
        this.$showSuccess('已更新標記');
      }, () => {
        this.$showError('更新標記失敗');
        this.loadData();
      });
    },
    changeBranch() {
      this.loadData();
    },
    setUserSearch(u) {
      if (u === undefined) {
        this.userSearch = false;
        this.userFilter = undefined;
      } else {
        this.userFilter = `${u.given_name}`;
      }
      this.currentPage = 1;
      this.loadData();
    },
    setProFilter(p) {
      if (p === undefined) {
        this.proSearch = false;
        this.proFilter = undefined;
      } else {
        this.proFilter = `${p.given_name}`;
      }
      this.currentPage = 1;
      this.loadData();
    },
    changeSearchUser() {
      this.userFilter = undefined;
    },
    getDefaultTime() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const start = new Date(now.getTime());
      const end = new Date(now.getTime() + 7 * 86400 * 1000);
      end.setHours(0, 0, 0, 0);
      return [start, end];
    },
    startEdit(data) {
      this.$root.$emit('start-record-edit', data);
    },
    goEdit({ start, end }) {
      this.$execWithLoading(async () => {
        const filter = this.getFilter();
        filter.start_at = start;
        filter.end_at = end;
        const datas = await getAppointments(filter);
        const data = datas.appointments.find((r) => r.start_at === start);
        if (data) {
        // this.$refs.personViewer.$emit('hide');
          this.startEdit(data);
        }
      });
    },
  },
  mounted() {
    this.times = this.getDefaultTime();
    setTimeout(() => {
      this.loadData();
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
.reserve-page {
  position: relative;
  padding: 30px 0;
  .search-bar {
    padding: 0 30px;
    .filter {
      .column {
        .title {
          font-size: 12px;
        }
        .date-range-input {
          width: 440px;
        }
        .place-input {
          width: 300px;
        }
      }
    }
  }
  .table {
    flex: 1;
  }
  .pagination {
    flex: 0 0 auto;
  }
  .empty {
    padding: 0 30px;
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
@import '@/assets/style/variables.scss';

.reserve-page {
  & > .table {
    margin: 0 30px;
    overflow: hidden;
    .td-tags {
      .cell {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
      }
    }
    .td-name {
      color: $primary-color;
    }
    .el-table, .el-table--enable-row-hover {
      .notpaid {
        background-color: #FEF0F0;
        td.el-table__cell {
          background-color: #FEF0F0 !important;
        }
      }
    }
  }

  // hack
  .el-table {
    border: 1px solid #EBEEF5;
    border-bottom: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    .el-table__header {
      thead {
        tr {
          th {
            background: #E6F4F4;
            border-left: 1px solid #EBEEF5;
          }
        }
      }
    }
    .el-table__body {
      tbody {
        tr {
          td {
            border-left: 1px solid #EBEEF5;
          }
        }
      }
    }

    // handle scroll
    .el-table__header-wrapper {
      flex: 0 0 auto;
    }
    .el-table__body-wrapper {
      flex: 1;
      overflow: overlay;
    }
  }
  .dropdown-button {
    padding: 7px;
  }
  .cancel-text {
    pointer-events: none;
    color: $text-placeholder-color;
  }
}
</style>
