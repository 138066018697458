import instance from '@/api/base';

export async function GetNotConfirmedPayments() {
  const rsp = await instance.get('/v1/monthly_payments');
  return rsp.data;
}

export async function GetConfirmedPayments() {
  const rsp = await instance.get('/v1/monthly_payments?confirmed=1');
  return rsp.data;
}

export async function GetNotNotifyPayments() {
  const rsp = await instance.get('/v1/monthly_payments?not-notify=1');
  return rsp.data;
}

export async function getMonthlyAccounting(year, month) {
  const rsp = await instance.get(`/v1/monthly_accounting/${year}/${month}`);
  return rsp.data;
}

export async function sendMonthlyAccountingResult(year, month, appointments, activities) {
  const rsp = await instance.put(`/v1/monthly_accounting/${year}/${month}`, {
    appointments,
    activities,
  });
  return rsp.data;
}

export async function getAccountingAppointments(params) {
  const rsp = await instance.get('/v1/accounting/appointments', { params });
  return rsp.data;
}

export async function setAccountingAppointmentStatus(id, confirmStatus) {
  const rsp = await instance.put(`/v1/accounting/appointment/${id}`, {
    confirm_status: confirmStatus ? 'confirmed' : 'wrong_amount',
  });
  return rsp.data;
}

export async function getMonthlyPayment(year, month) {
  const rsp = await instance.get(`/v1/monthly_payments/${year}/${month}`);
  return rsp.data;
}

export async function getMonthlyPaymentDetail(year, month) {
  const rsp = await instance.get(`/v1/monthly_payments/${year}/${month}/detail`);
  return rsp.data;
}

export async function setMonthPaymentStatus(year, month, status) {
  const rsp = await instance.put(`/v1/monthly_payments/${year}/${month}`, {
    confirm_status: status,
  });
  return rsp.data;
}
