<template lang="pug">
.income-page.flex.column.align-center.gap-40
  .part.flex.column.align-left.gap-10
    b.title.font-size-20 單筆對帳
    template(v-for="month in confirmMonth")
      .accounting-card.card-style.flex.column.flex.row.align-stretch(:key="`${month.year}-${month.month}`")
        .card-left.flex-1.flex-column.gap-10
          b.card-title.font-size-14 {{ month.year }} 年 {{ month.month }} 月
          .card-desc
            span 請於{{ month.month % 12 + 1 }}月5日23:59前送出確認，以便蛹之生核算當月薪資。
            b 逾期項目將於後續月份處理。
        .card-right.flex.row.align-center.flex-auto
          el-button(type="primary", size="small", @click="goMonth(month.year, month.month)") 開始
    .text-clickable(@click="goAccountListPage") 查看過去紀錄
  .part.flex.column.align-left.gap-10
    b.title.font-size-20 薪資單確認
    template(v-for="month in confirmPayments")
      .accounting-card.card-style.flex.column.flex.row.align-stretch(:key="`${month.year}-${month.month}`")
        .card-left.flex-1.flex-column.gap-10
          b.card-title.font-size-14 {{ month.year }} 年 {{ month.month }} 月
          .card-desc
            span 請於{{ month.month }}月10日23:59前完成確認，以便蛹之生核算當月薪資
        .card-right.flex.row.align-center.flex-auto
          el-button(type="primary", size="small", @click="goPaymentMonth(month.year, month.month)") 開始
    template(v-if="confirmPayments.length === 0")
      span 尚無未確認的薪資明細
    .text-clickable(@click="goPayslipsPage") 查看過去紀錄
  .part.flex.column.align-left.gap-10
    b.title.font-size-20 未出帳薪資明細
    template(v-for="month in notNotifyPayments")
      .accounting-card.card-style.flex.column.flex.row.align-stretch(:key="`${month.year}-${month.month}`")
        .card-left.flex-1.flex-column.gap-10
          b.card-title.font-size-14 {{ month.year }} 年 {{ month.month }} 月
        .card-right.flex.row.align-center.flex-auto
          el-button(type="primary", size="small", @click="goPaymentMonthDetail(month.year, month.month)") 瀏覽
    template(v-if="notNotifyPayments.length === 0")
      span 尚無未確認的薪資明細
</template>

<script>
import {
  GetNotConfirmedPayments,
  GetNotNotifyPayments,
} from '@/site/professional/api/accounting';

export default {
  data() {
    return {
      confirmMonth: [],
      confirmPayments: [],
      notNotifyPayments: [],
    };
  },
  methods: {
    goAccountListPage() {
      this.$router.push('/payments');
    },
    goPayslipsPage() {
      this.$router.push('/payslips');
    },
    async loadData() {
      return this.$execWithLoading(async () => Promise.all([
        this.loadUnconfirmData(),
        this.loadNotNotifiedData(),
      ]));
    },
    async loadUnconfirmData() {
      const rsp = await GetNotConfirmedPayments();
      this.confirmPayments = rsp;
      if (rsp.length > 0) {
        this.$root.$emit('show-notification');
      }
    },
    async loadNotNotifiedData() {
      this.notNotifyPayments = await GetNotNotifyPayments();
    },
    loadDataTmp() {
      const now = new Date();
      now.setDate(1);
      now.setHours(0, 0, 0, 0);

      const monthes = [];
      for (let i = 0; i < 2; i += 1) {
        const target = new Date(now.setMonth(now.getMonth() - i));
        const year = target.getFullYear();
        const month = target.getMonth() + 1;
        monthes.push({ year, month });
      }
      console.log(monthes);
      this.confirmMonth = monthes;
    },
    goMonth(year, month) {
      this.$router.push(`/income/${year}/${month}`);
    },
    goPaymentMonth(year, month) {
      this.$router.push(`/payment/${year}/${month}`);
    },
    goPaymentMonthDetail(year, month) {
      this.$router.push(`/payment/${year}/${month}/detail`);
    },
  },
  mounted() {
    this.loadDataTmp();
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.income-page {
  padding-top: 40px;
  .part {
    width: 600px;
    .accounting-card {
      padding: 10px 20px 10px 20px;
      .card-desc {
        font-size: 13px;
        color: #E6A23C;
      }
    }
  }
}
</style>
