import Login from '../views/LoginPage.vue';
import TodayPage from '../views/TodayPage.vue';
import AccountPage from '../views/AccountPage.vue';
import AppointmentPage from '../views/ReservePage.vue';
import ActivityPage from '../views/ActivityPage.vue';
import PasswordForget from '../views/ForgetPassword.vue';
import IncomePage from '../views/IncomePage.vue';
import IncomeListPage from '../views/IncomeListPage.vue';
import PaymentListPage from '../views/PaymentListPage.vue';
import IncomeMonthPage from '../views/IncomeMonthPage.vue';
import PaymentMonthPage from '../views/PaymentMonthPage.vue';
import PaymentMonthDetailPage from '../views/PaymentMonthDetailPage.vue';

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/today',
    name: 'Today',
    component: TodayPage,
    needAuth: true,
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountPage,
    needAuth: true,
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: AppointmentPage,
    needAuth: true,
  },
  {
    path: '/forget-password',
    name: 'PasswordForget',
    component: PasswordForget,
  },
  {
    path: '/activity',
    name: 'Activity',
    component: ActivityPage,
    needAuth: true,
  },
  {
    path: '/income',
    name: 'Income',
    component: IncomePage,
    needAuth: true,
  },
  {
    path: '/payslips',
    name: 'Payslips',
    component: IncomeListPage,
    needAuth: true,
  },
  {
    path: '/income/:year/:month',
    name: 'IncomeMonth',
    component: IncomeMonthPage,
    needAuth: true,
    props: true,
  },
  {
    path: '/payments',
    name: 'Payments',
    component: PaymentListPage,
    needAuth: true,
  },
  {
    path: '/payment/:year/:month',
    name: 'PaymentMonth',
    component: PaymentMonthPage,
    needAuth: true,
    props: true,
  },
  {
    path: '/payment/:year/:month/detail',
    name: 'PaymentMonthDetail',
    component: PaymentMonthDetailPage,
    needAuth: true,
    props: true,
  },
  {
    path: '*',
    redirect: '/today',
  },
];
